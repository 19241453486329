import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Level from "./Level";
import GameLevel from "../assests/images/game.svg";
import GameLevel1 from "../assests/images/gamelevel1.svg";
import GameLevel2 from "../assests/images/gamelevel2.svg";
import { Image, Container } from "react-bootstrap";
import SidebarLayout from "../sidebarMenu/index";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import LevelName from "../assests/images/level.svg";
import WelComeSection from "../welcomeSection/index";
import Config from "../../config";

const GameMap = () => {
  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { subject, paperType, paperInfo, syllabusId, mediumId, gradeId } =
    location.state;

  useEffect(() => {
    const fetchLevels = async () => {
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      try {
        const response = await fetch(
          `${Config.API_URL}/api/v1/mobile/level?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}&subjectId=${subject}&paperTypeId=${paperType}&paperInfoId=${paperInfo}&userId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result = await response.json();
        const levelsData = result.levels.map((level, index) => ({
          id: level.levelNo,
          isLocked: !level.isOpen,
          imageSrc:
            index % 2 === 0
              ? GameLevel
              : index % 3 === 0
              ? GameLevel2
              : GameLevel1,
          top: `${30 + Math.random() * 40}%`,
          left: `${index * 14 + Math.random() * 4}%`,
        }));

        setLevels(levelsData);
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };

    fetchLevels();
  }, [subject, paperType, paperInfo, syllabusId, mediumId, gradeId]);

  const handleLevelClick = (levelNumber) => {
    console.log(`Level ${levelNumber} clicked!`);
    navigate("/question-type-1", {
      state: {
        subject,
        paperType,
        paperInfo,
        syllabusId,
        mediumId,
        gradeId,
        levelNumber,
      },
    });
  };

  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <Container>
            <div className="gameLevelFrame">
              <div className="levelImg">
                <Image src={LevelName} alt="image" fluid />
              </div>
              <div className="game-map">
                {levels.map((level) => (
                  <div
                    key={level.id}
                    className="level-container"
                    style={{
                      position: "absolute",
                      top: level.top,
                      left: level.left,
                    }}
                  >
                    <div className="LevelImage">
                      <Image
                        src={level.imageSrc}
                        alt={`Level ${level.id}`}
                        className="LevelImage"
                      />
                    </div>
                    <Level
                      levelNumber={level.id}
                      isLocked={level.isLocked}
                      onClick={() => handleLevelClick(level.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
};

export default GameMap;
