import React, { useState, useEffect } from "react";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Image, Spinner } from "react-bootstrap";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import VictoryImg from "../assests/images/victory3start.svg";
import PlayerTwo from "../assests/images/player02.svg";
import Star from "../assests/images/startOneVsOnE.svg";
import WelComeSection from "../welcomeSection/index";
import Config from "../../config";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function VictoryThreeStart() {
  const [loading, setLoading] = useState(true);
  const [profilePic, setProfilePic] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const userName = localStorage.getItem("UserName");
  const userId = localStorage.getItem("UserID");
  const token = localStorage.getItem("authToken");

  const {
    correctAnswersCount = 0,
    opponentPoints = 0,
    userAnswers,
    questions,
    subjectId,
    paperTypeId,
    paperInfoId,
    levelNumber,
    playerName,
  } = location.state || {};

  const handleCheckAnswers = () => {
    const from = location.state?.from;

    if (from === "/one-vs-one-questions") {
      navigate("/one-vs-one-answer", {
        state: {
          subjectId,
          paperTypeId,
          paperInfoId,
          levelNumber,
          userAnswers,
          questions,
        },
      });
    } else {
      navigate("/home");
    }
  };

  const handleHome = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (userId && token) {
      fetch(`${Config.API_URL}/api/v1/mobile/user/details/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.profilePicture) {
            setProfilePic(
              `${Config.API_URL}/api/v1/uploads/profile-pictures/${data.profilePicture}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile picture:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId, token]);
  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            {" "}
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            {" "}
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            {" "}
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <Container>
            <div className="victoryFrame">
              <div className="victoryImg">
                <Image src={VictoryImg} alt="victoryImg" fluid />
              </div>
              <div className="playerAndHostSection">
                <div className="hostDeatils">
                  <div>
                    {loading ? (
                      <Spinner size="large" />
                    ) : (
                      profilePic && (
                        <Image
                          src={profilePic}
                          alt="Profile"
                          roundedCircle
                          className="profileOnevsOneLeaderBoard"
                        />
                      )
                    )}
                  </div>
                  <div className="hostName">
                    <span>{userName}&nbsp;(You)</span>
                  </div>
                  <div className="OneVsOnePoint">
                    <span>
                      {correctAnswersCount}&nbsp;&nbsp;&nbsp;
                      <Image src={Star} fluid />
                    </span>
                  </div>
                </div>
                <div className="playerDeatils">
                  <div>
                    <Image
                      src={PlayerTwo}
                      alt="player"
                      className="profileOnevsOneLeaderBoard"
                      fluid
                    />
                  </div>

                  <div className="hostName">
                    <span>{playerName}</span>
                  </div>
                  <div className="OneVsOnePoint">
                    <span>
                      {opponentPoints}&nbsp;&nbsp;&nbsp;
                      <Image src={Star} fluid />
                    </span>
                  </div>
                </div>
              </div>
              <div className="victoryBtnSection">
                <div className="nextBtnVictory">
                  <button onClick={handleHome}>Home</button>
                </div>
                <div className="checkAnswerBtn">
                  <button onClick={handleCheckAnswers}>Check Answers</button>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
