import React, { useState } from "react";
import { Container, Image, Modal, Form, Spinner } from "react-bootstrap";
import TopImg from "../assests/images/topImg.svg";
import WelComeSection from "../welcomeSection/index";
import ModalIcon from "../assests/images/modalIcon.svg";
import { useNavigate } from "react-router-dom";
import Config from "../../config/index";

export default function TopSection() {
  const [subjects, setSubjects] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperInfo, setPaperInfo] = useState([]);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingPaperTypes, setLoadingPaperTypes] = useState(false);
  const [loadingPaperInfo, setLoadingPaperInfo] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedPaperType, setSelectedPaperType] = useState("");
  const [selectedPaperInfo, setSelectedPaperInfo] = useState("");
  const [showPlayModal, setShowPlayModal] = useState(false);
  const [syllabusId, setSyllabusId] = useState("");
  const [mediumId, setMediumId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const navigate = useNavigate();

  const handlePlayModal = () => {
    setShowPlayModal(true);
    fetchUserDetails();
    fetchPaperTypes();
  };

  const handleClose = () => {
    setSelectedSubject("");
    setSelectedPaperType("");
    setSelectedPaperInfo("");
    setShowPlayModal(false);
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        throw new Error("User credentials are missing");
      }

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const userDetails = await response.json();

      const { grade, syllabus, medium } = userDetails;
      const { _id: fetchedGradeId } = grade;
      const { _id: fetchedSyllabusId } = syllabus;
      const { _id: fetchedMediumId } = medium;

      setGradeId(fetchedGradeId);
      setSyllabusId(fetchedSyllabusId);
      setMediumId(fetchedMediumId);

      await fetchSubjects(fetchedSyllabusId, fetchedMediumId, fetchedGradeId);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchSubjects = async (syllabusId, mediumId, gradeId) => {
    setLoadingSubjects(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/subject/by-syllabus-medium-grade?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setSubjects(result.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoadingSubjects(false);
    }
  };

  const fetchPaperTypes = async (selectedSubjectId) => {
    setLoadingPaperTypes(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperType/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubjectId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperTypes(result.paperTypes || []);
    } catch (error) {
      console.error("Error fetching paper types:", error);
    } finally {
      setLoadingPaperTypes(false);
    }
  };

  const fetchPaperInfo = async (selectedPaperTypeId) => {
    setLoadingPaperInfo(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperInfo/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubject}&paperTypeId=${selectedPaperTypeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperInfo(result.paperInfos || []);
    } catch (error) {
      console.error("Error fetching paper info:", error);
    } finally {
      setLoadingPaperInfo(false);
    }
  };

  const handleSubjectChange = async (e) => {
    const selectedSubjectId = e.target.value;
    setSelectedSubject(selectedSubjectId);

    if (selectedSubjectId && syllabusId && gradeId) {
      await fetchPaperTypes(selectedSubjectId);
    }
  };

  const handlePaperTypeChange = async (e) => {
    const selectedPaperTypeId = e.target.value;
    setSelectedPaperType(selectedPaperTypeId);

    if (selectedPaperTypeId && syllabusId && gradeId && selectedSubject) {
      await fetchPaperInfo(selectedPaperTypeId);
    }
  };

  const handleStartClick = () => {
    if (selectedSubject && selectedPaperType && selectedPaperInfo) {
      navigate("/game-map", {
        state: {
          subject: selectedSubject,
          paperType: selectedPaperType,
          paperInfo: selectedPaperInfo,
          syllabusId: syllabusId,
          mediumId: mediumId,
          gradeId: gradeId,
        },
      });
    } else {
      alert("Please select all fields before proceeding!");
    }
  };

  return (
    <div>
      <Container>
        <div>
          <div className="welcomeNote">
            <WelComeSection />
          </div>
        </div>

        <div className="topImage">
          <Image src={TopImg} alt="logo" fluid />
        </div>

        <div className="PlayNow">
          <button onClick={handlePlayModal}>Play Now</button>
        </div>

        <Modal className="modalPlay" show={showPlayModal} onHide={handleClose}>
          <Modal.Header className="modalHeader" closeButton></Modal.Header>
          <Modal.Body>
            <div className="ModelIcon">
              <img src={ModalIcon} alt="icon" />
            </div>
            <div className="oneMoreText">
              <span>One More Step to go !</span>
            </div>
            <div className="pleseSelectText">
              <span>Please select to start the game.</span>
            </div>

            <form>
              <div>
                <div className="InputText-PlayModal">
                  <span>Subject</span>
                </div>
                {loadingSubjects ? (
                  <Spinner animation="border" />
                ) : (
                  <Form.Select
                    aria-label="Select your subject"
                    value={selectedSubject}
                    onChange={handleSubjectChange}
                  >
                    <option>Select your syllabus</option>
                    {subjects.map((subject) => (
                      <option key={subject._id} value={subject._id}>
                        {subject.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>

              <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                <div className="InputText-PlayModal">
                  <span>Paper Type</span>
                </div>
                {loadingPaperTypes ? (
                  <Spinner animation="border" />
                ) : (
                  <Form.Select
                    aria-label="Select your paperType"
                    value={selectedPaperType}
                    onChange={handlePaperTypeChange}
                  >
                    <option>Select your paperType</option>
                    {paperTypes.map((paperType) => (
                      <option key={paperType._id} value={paperType._id}>
                        {paperType.paperTypeName}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>

              <div style={{ marginBottom: "20px" }}>
                <div className="InputText-PlayModal">
                  <span>Paper Info</span>
                </div>
                {loadingPaperInfo ? (
                  <Spinner animation="border" />
                ) : (
                  <Form.Select
                    aria-label="Select your paper info"
                    value={selectedPaperInfo}
                    onChange={(e) => setSelectedPaperInfo(e.target.value)}
                  >
                    <option>Select your paper info</option>
                    {paperInfo.map((info) => (
                      <option key={info._id} value={info._id}>
                        {info.paperInfo}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </form>

            <div className="modalStartBtn">
              <button onClick={handleStartClick}>Start</button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}
