import React, { useState, useEffect } from "react";
import SidebarLayout from "../sidebarMenu/index";
import OtherOption from "../home/otherOption";
import BottomSection from "./bottomSection";
import TopSection from "./topSection";
import BannerPopup from "../BannerPopup/index";

export default function HomePage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoggedIn(true);
    }, 1000);
    if (isLoggedIn) {
      const bannerShown = localStorage.getItem("bannerShown");

      if (!bannerShown) {
        setTimeout(() => {
          setShowBanner(true);
          localStorage.setItem("bannerShown", "true");
        }, 500);
      }
    }
  }, [isLoggedIn]);

  return (
    <SidebarLayout>
      <div className="HomePage">
        {showBanner && <BannerPopup />}
        <TopSection />
        <OtherOption />
        <BottomSection />
      </div>
    </SidebarLayout>
  );
}
