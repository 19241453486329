import React, { useState, useEffect } from "react";

const BannerPopup = () => {
  const [bannerData, setBannerData] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [animateClose, setAnimateClose] = useState(false);

  useEffect(() => {
    fetch("/banner.json")
      .then((response) => response.json())
      .then((data) => setBannerData(data.banner))
      .catch((error) => console.error("Error fetching banner:", error));
  }, []);

  const handleClosePopup = () => {
    setAnimateClose(true);
    setTimeout(() => setShowPopup(false), 300);
  };

  if (!showPopup || !bannerData) {
    return null;
  }

  return (
    <div
      style={
        animateClose
          ? { ...styles.overlay, ...styles.fadeOut }
          : { ...styles.overlay, ...styles.fadeIn }
      }
    >
      <div
        style={
          animateClose
            ? { ...styles.popup, ...styles.popupFadeOut }
            : { ...styles.popup, ...styles.popupFadeIn }
        }
      >
        <button onClick={handleClosePopup} style={styles.closeButton}>
          X
        </button>
        <img
          src={bannerData.imageUrl}
          alt="Banner"
          style={styles.bannerImage}
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  fadeIn: {
    opacity: 1,
  },
  fadeOut: {
    opacity: 0,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)",
    maxWidth: "500px",
    position: "relative",
    transform: "scale(0.95)",
    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
    opacity: 0,
  },
  popupFadeIn: {
    opacity: 1,
    transform: "scale(1)",
  },
  popupFadeOut: {
    opacity: 0,
    transform: "scale(0.9)",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  bannerImage: {
    maxWidth: "100%",
    borderRadius: "5px",
  },
};

export default BannerPopup;
