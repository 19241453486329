import React, { useEffect, useState } from "react";
import Config from "../../config/index";
import "./style.css";
import LoginLogo from "../assests/images/loginLogo.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import { Container, Image, Form, Alert } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";

export default function Index() {
  const [countries, setCountries] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${Config.API_URL}/api/v1/country`)
      .then((response) => response.json())
      .then((data) => setCountries(data))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleNext = () => {
    if (!fullName || !email || !password || !selectedCountry) {
      setError("All fields are required!");
      return;
    }
    if (!isAgreed) {
      setError("You must agree to the terms & conditions!");
      return;
    }
    navigate("/signup-step-2", {
      state: { fullName, email, password, selectedCountry },
    });
  };
  return (
    <div className="SignUpContainer">
      <Container>
        <div className="LeftCloud">
          {" "}
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          {" "}
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          {" "}
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="signUpForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>
            <div className="Welcometext">
              <span>User Registration</span>
            </div>
            <div className="welcomesubtext">
              <span>Create account using your details</span>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="inputfield">
              <div className="InputText">
                <span>Your Country</span>
              </div>
              <Form.Select
                aria-label="Select your country"
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option>Select your country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="inputfield">
              <div className="InputText">
                <span>Full Name</span>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter your full name here"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className="inputfield">
              <div className="InputText">
                <span>Email</span>
              </div>
              <Form.Control
                type="email"
                placeholder="Enter your  email here"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="inputfield">
              <div className="InputText">
                <span>Password</span>
              </div>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </div>

            <div className="inputfield">
              <div className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="checkbox"
                  label="I agree with your terms & conditions"
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
              </div>
            </div>

            <div className="SignInBtn">
              <button onClick={handleNext}>Next</button>
            </div>

            <div className="SignUpText">
              <span className="SignUpText">Already have an account ?</span>
              &nbsp;&nbsp;
              <NavLink to="/">
                <span className="signup">Log In</span>
              </NavLink>
            </div>
          </div>
        </center>
        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>
      <div className="signupBottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
