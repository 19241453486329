import React, { useState, useEffect } from "react";
import Config from "../../config/index";
import {
  Container,
  Image,
  DropdownButton,
  Dropdown,
  InputGroup,
  Form,
  Alert,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.css";
import LoginLogo from "../assests/images/loginLogo.svg";
import Google from "../assests/images/Google.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import { get } from "country-flag-emoji";

export default function Index() {
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedDialCode, setSelectedDialCode] = useState("Code");
  const [searchQuery, setSearchQuery] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${Config.API_URL}/api/v1/country`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
        setFilteredCountries(data);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredCountries(countries);
    } else {
      const filtered = countries.filter(
        (country) =>
          country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          country.dial_code.includes(searchQuery)
      );
      setFilteredCountries(filtered);
    }
  }, [searchQuery, countries]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    const fullPhoneNumber = selectedDialCode + phoneNumber;

    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: fullPhoneNumber,
            password: password,
          }),
        }
      );

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        localStorage.setItem("authToken", result.token);
        localStorage.setItem("UserName", result.data.name);
        localStorage.setItem("UserID", result.data._id);
        navigate("/home");
      } else {
        setErrorMessage(result.message || "Login failed. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="LoginContainer">
      <Container>
        <div className="LeftCloud">
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="loginForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>
            <div className="Welcometext">
              <span>Welcome Back !</span>
            </div>
            <div className="welcomesubtext">
              <span>Please Login with your ExamHub account</span>
            </div>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form onSubmit={handleLogin}>
              <div>
                <div className="InputText">
                  <span>Phone Number</span>
                </div>
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="light"
                    title={selectedDialCode}
                    id="input-group-dropdown-1"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search by country or dial code"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ marginBottom: "10px" }}
                      />
                      {filteredCountries.length > 0 ? (
                        filteredCountries.map((country) => {
                          const emojiFlag = get(country.code)?.emoji || "🏳️";
                          return (
                            <Dropdown.Item
                              key={country.code}
                              onClick={() =>
                                setSelectedDialCode(country.dial_code)
                              }
                            >
                              <span style={{ marginRight: "10px" }}>
                                {emojiFlag}
                              </span>
                              {country.name} ({country.dial_code})
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <Dropdown.Item disabled>
                          No matching results
                        </Dropdown.Item>
                      )}
                    </div>
                  </DropdownButton>
                  <Form.Control
                    aria-label="Phone Number"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </InputGroup>
              </div>

              <div>
                <div className="InputText">
                  <span>Password</span>
                </div>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="InputText">
                <NavLink
                  to="/fogotpassword-step-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Forgot Password?</span>
                </NavLink>
              </div>
              <br />

              <div className="SignInBtn">
                <button type="submit" disabled={loading}>
                  {loading ? "Signing in..." : "Sign in"}
                </button>
              </div>
            </Form>

            <div className="contunueText">
              <span>or continue with</span>
            </div>

            <div className="GoogleSignInBtn">
              <button>
                <Image src={Google} alt="logo" fluid />
                &nbsp; &nbsp; &nbsp; Sign in with Google
              </button>
            </div>
            <div className="SignUpText">
              <span className="SignUpText">Don’t have an account yet?</span>
              &nbsp;&nbsp;
              <NavLink to="/signup-step-1">
                <span className="signup">Sign Up</span>
              </NavLink>
            </div>
          </div>
        </center>

        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>
      <div className="Bottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
