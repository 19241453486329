import React, { useState } from "react";
import { Modal, Form, Image } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";
import TeamVsTeamStep3 from "./TeamVsTeamStep_3";
import "./style.css";

export default function TeamVsTeamModalStep_2({ show, handleClose }) {
  const [showTeamVsTeamStep3Create, setShowTeamVsTeamStep3Create] =
    useState(false);

  const handleOpenCreateGame = () => {
    handleClose();
    setTimeout(() => {
      setShowTeamVsTeamStep3Create(true);
    }, 250);
  };

  const handleCloseCreateGame = () => {
    setShowTeamVsTeamStep3Create(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modalPlay">
        <Modal.Header className="modalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="teamVsModal">
            <Image src={Team} alt="vsLogo" fluid rounded />
            <Image src={VS} alt="vsLogo" fluid rounded />
            <Image src={Team} alt="vsLogo" fluid rounded />
          </div>
          <div className="createGameTextOneVs">
            <span>Create the Game</span>
          </div>
          <div className="selectforStartTextOneVs">
            <span>Please select for start the game</span>
          </div>
          <form>
            <div>
              <div className="InputText-PlayModal">
                <span>Subject</span>
              </div>
              <Form.Select aria-label="Default select example">
                <option>Select your subject</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>

            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Paper Type</span>
              </div>
              <Form.Select aria-label="Default select example">
                <option>Select your paper type</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>

            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Paper</span>
              </div>
              <Form.Select aria-label="Default select example">
                <option>Select the paper</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>

            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Level</span>
              </div>
              <Form.Select aria-label="Default select example">
                <option>Select the level</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
            <div className="modalStartBtn">
              <button type="button" onClick={handleOpenCreateGame}>
                Create
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <TeamVsTeamStep3
        show={showTeamVsTeamStep3Create}
        handleClose={handleCloseCreateGame}
      />
    </div>
  );
}
