import React, { useState, useEffect } from "react";
import { Modal, Spinner, Image, Alert } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import Copy from "../assests/images/copy.svg";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import Config from "../../config";

const socket = io(`${Config.API_URL}`);

export default function OneVsOneStep_3({
  show,
  handleClose,
  gameId,
  selectedSubject,
  selectedPaperType,
  selectedPaperInfo,
  levelNumber,
  subjectId,
  paperTypeId,
  paperInfoId,
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [playerCount, setPlayerCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("UserID");

    socket.emit("join", { userId });

    socket.on("player_joined", (data) => {
      const playerName = data?.userName || "";
      setPlayerCount((prevCount) => prevCount + 1);

      if (playerCount >= 1) {
        setLoading(false);
      }
      navigate("/one-vs-one-step-4", {
        state: {
          gameId,
          selectedSubject,
          selectedPaperType,
          selectedPaperInfo,
          levelNumber,
          playerName,
          subjectId,
          paperTypeId,
          paperInfoId,
        },
      });

      if (!data.userId === 0) {
        setLoading(false);
      }
    });

    return () => {
      socket.off("player_joined");
    };
  }, [
    playerCount,
    gameId,
    selectedSubject,
    selectedPaperType,
    selectedPaperInfo,
    levelNumber,
    subjectId,
    paperTypeId,
    paperInfoId,
    navigate,
  ]);

  const handleCopyGameId = () => {
    navigator.clipboard
      .writeText(gameId)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} className="modalPlay">
      <Modal.Header className="modalHeader" closeButton></Modal.Header>
      <Modal.Body>
        <div className="OneVsModelText">
          <span>1</span>
          <img src={VS} alt="vsLogo" />
          <span>1</span>
        </div>
        <div className="beReadyText">
          <span>Please wait ..</span>
        </div>
        <div className="gameWillStartText">
          <span>Your friends are connecting..</span>
        </div>
        {loading && (
          <div className="spinnerAnimation">
            <Spinner animation="border" role="status" className="spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="pleseWaitText">
          <span>Game ID</span>
          <span style={{ display: "flex" }}>
            {gameId} &nbsp;&nbsp;
            <Image
              src={Copy}
              style={{ cursor: "pointer" }}
              onClick={handleCopyGameId}
              alt="logo"
              fluid
            />
          </span>
        </div>
        <div className="gameIdShowText">
          <span>Share this game id with your friends and play as a team</span>
        </div>

        {showAlert && (
          <Alert
            variant="success"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Game ID copied to clipboard!
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
}
