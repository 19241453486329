import React, { useState, useEffect } from "react";
import {
  MenuOutlined,
  HomeOutlined,
  FileSyncOutlined,
  TeamOutlined,
  SettingOutlined,
  LogoutOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Spin } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import ExamHubLogo from "../assests/images/examHubLogo.svg";
import { Image } from "react-bootstrap";
import Config from "../../config";

const { Sider, Content } = Layout;

const Index = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("UserID");
  const token = localStorage.getItem("authToken");

  const getCurrentKey = (pathname) => {
    switch (pathname) {
      case "/home":
        return "1";
      case "/change-syllabus":
        return "2";
      case "/leaderboard":
        return "3";
      case "/setting":
        return "4";
      default:
        return "1";
    }
  };

  const handleThreeDotClick = () => {
    setShowLogout(!showLogout);
  };

  const handleLogout = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("token");
    localStorage.removeItem("UserID");
    localStorage.removeItem("bannerShown");
    navigate("/");
  };

  useEffect(() => {
    if (userId && token) {
      fetch(`${Config.API_URL}/api/v1/mobile/user/details/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.profilePicture) {
            setProfilePic(
              `${Config.API_URL}/api/v1/uploads/profile-pictures/${data.profilePicture}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile picture:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId, token]);

  return (
    <Layout
      className="sideMenu"
      style={{ minHeight: "100vh", background: "#1C3055" }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          height: "100vh",
          background: "#1C3055",
        }}
      >
        <Button
          type="text"
          icon={<MenuOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "24px",
            color: "#fff",
            width: "100%",
            textAlign: "center",
            margin: "16px 0",
          }}
        />
        <div className="logo">
          <Image src={ExamHubLogo} alt="Examhub" />
        </div>
        <div className="hr">
          <span>
            <hr />
          </span>
        </div>

        <Menu
          mode="vertical"
          selectedKeys={[getCurrentKey(location.pathname)]}
          style={{ background: "#1C3055" }}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <NavLink className="NavLinkName" to="/home">
              Home
            </NavLink>
          </Menu.Item>
          <Menu.Item key="2" icon={<FileSyncOutlined />}>
            <NavLink className="NavLinkName" to="/change-syllabus">
              Change Syllabus
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3" icon={<TeamOutlined />}>
            <NavLink className="NavLinkName" to="/leaderboard">
              Leaderboard
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4" icon={<SettingOutlined />}>
            <NavLink className="NavLinkName" to="/setting">
              Settings
            </NavLink>
          </Menu.Item>
        </Menu>

        <div className="scroll-icons">
          <div className="icon-container">
            <SoundOutlined className="scroll-icon" />
            <LogoutOutlined className="scroll-icon" onClick={handleLogout} />
            <div className="threeDot" onClick={handleThreeDotClick}>
              <span>...</span>
            </div>
          </div>
          {showLogout && (
            <div className="logout-popup">
              <LogoutOutlined
                style={{ color: "#fff", fontSize: "16px", marginLeft: "10px" }}
              />
              <span style={{ color: "#fff", marginLeft: "8px" }}>Logout</span>
            </div>
          )}
        </div>
        <div className="profile-avatar">
          {loading ? (
            <Spin size="large" />
          ) : (
            profilePic && <Image src={profilePic} alt="Profile" roundedCircle />
          )}
        </div>
      </Sider>
      <Layout className="content">
        <Content className="content">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Index;
