import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ModalIcon from "../assests/images/modalIcon.svg";
import VS from "../assests/images/vs.svg";
import OneVsOneStep2 from "./OneVsOneStep_2";
import OneVsOneJoinGame1 from "./OneVsOneJoinGame1";
import "./style.css";

export default function OneVsOneModal({ show, handleClose }) {
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [showOneVsOneJoinGame1, setShowOneVsOneJoinGame1] = useState(false);

  const handleOpenCreateGame = () => {
    handleClose();
    setTimeout(() => {
      setShowCreateGameModal(true);
    }, 250);
  };

  const handleOpenJoinGame = () => {
    handleClose();
    setTimeout(() => {
      setShowOneVsOneJoinGame1(true);
    }, 250);
  };

  const handleCloseCreateGame = () => {
    setShowCreateGameModal(false);
    setShowOneVsOneJoinGame1(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modalPlay">
        <Modal.Header className="modalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="ModelIcon">
            <img src={ModalIcon} alt="icon" />
          </div>
          <div className="oneVsCreateNewText">
            <span>Create new game or Join your friend’s game</span>
          </div>
          <div className="OneVsModelText">
            <span>1</span>
            <img src={VS} alt="vsLogo" />
            <span>1</span>
          </div>
          <div className="oneVsButtonSection">
            <div className="createGameBtn">
              <button onClick={handleOpenCreateGame}>Create Game</button>
            </div>

            <div className="joinGameBtn">
              <button onClick={handleOpenJoinGame}>Join Game</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <OneVsOneStep2
        show={showCreateGameModal}
        handleClose={handleCloseCreateGame}
      />
      <OneVsOneJoinGame1
        show={showOneVsOneJoinGame1}
        handleClose={handleCloseCreateGame}
      />
    </div>
  );
}
