import React, { useState, useRef } from "react";
import LoginLogo from "../assests/images/loginLogo.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import { Container, Image, Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Config from "../../config/index";
import "./style.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `${Config.API_URL}/api/v2/mobile/user/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
      } else {
        setError(data.message || "Failed to send OTP");
      }
    } catch (error) {
      setError("Something went wrong! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOtp = () => {
    const otpCode = otp.join("");
    navigate("/fogotpassword-step-2", { state: { otpCode, email } });
  };

  return (
    <div className="LoginContainer">
      <Container>
        <div className="LeftCloud">
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="loginForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>

            <div className="Welcometext">
              <span>Account Recovery</span>
            </div>
            <div className="welcomesubtext">
              <span>Enter your account email to verify</span>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}

            <div className="inputfield">
              <div className="InputText">
                <span>Email</span>
              </div>
              <Form.Control
                type="email"
                placeholder="username@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="InputText" style={{ marginTop: "10px" }}>
              <Button
                variant="primary"
                onClick={handleSendOtp}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Code"}
              </Button>
            </div>

            {otpSent && (
              <>
                <br />
                <br />
                <br />
                <div className="verficationText">
                  <span>We have sent the verification code to your email</span>
                </div>
                <div className="verficationText">
                  <span>Please enter the 6 digits code that we have sent</span>
                </div>

                <div className="game-id-inputs-fogot">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="game-id-fogot"
                    />
                  ))}
                </div>

                <div className="SignInBtn">
                  <Button variant="primary" onClick={handleVerifyOtp}>
                    Verify
                  </Button>
                </div>
              </>
            )}
          </div>
        </center>

        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>

      <div className="Bottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
