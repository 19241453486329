import React, { useState, useEffect } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import OneVsOneStep3 from "./OneVsOneStep_3";
import Config from "../../config/index";
import "./style.css";

export default function OneVsOneStep_2({ show, handleClose }) {
  const [showOneVsOneStep3, setShowOneVsOneStep3] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperInfo, setPaperInfo] = useState([]);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingPaperTypes, setLoadingPaperTypes] = useState(false);
  const [loadingPaperInfo, setLoadingPaperInfo] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedPaperType, setSelectedPaperType] = useState("");
  const [selectedPaperInfo, setSelectedPaperInfo] = useState("");
  const [syllabusId, setSyllabusId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [gameId, setGameId] = useState("");
  const [levelNumber, setLevelNumber] = useState("");

  const handleCloseCreateGame = () => {
    setShowOneVsOneStep3(false);
    setSelectedSubject("");
    setSelectedPaperType("");
    setSelectedPaperInfo("");
    setGameId("");
    setLevelNumber("");
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        throw new Error("User credentials are missing");
      }

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const userDetails = await response.json();

      const { grade, syllabus, medium } = userDetails;
      const { _id: fetchedGradeId } = grade;
      const { _id: fetchedSyllabusId } = syllabus;
      const { _id: fetchedMediumId } = medium;

      setGradeId(fetchedGradeId);
      setSyllabusId(fetchedSyllabusId);

      await fetchSubjects(fetchedSyllabusId, fetchedMediumId, fetchedGradeId);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const fetchSubjects = async (syllabusId, mediumId, gradeId) => {
    setLoadingSubjects(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/subject/by-syllabus-medium-grade?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setSubjects(result.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoadingSubjects(false);
    }
  };

  const fetchPaperTypes = async (selectedSubjectId) => {
    setLoadingPaperTypes(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperType/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubjectId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperTypes(result.paperTypes || []);
    } catch (error) {
      console.error("Error fetching paper types:", error);
    } finally {
      setLoadingPaperTypes(false);
    }
  };

  const fetchPaperInfo = async (selectedPaperTypeId) => {
    setLoadingPaperInfo(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperInfo/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubject}&paperTypeId=${selectedPaperTypeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperInfo(result.paperInfos || []);
    } catch (error) {
      console.error("Error fetching paper info:", error);
    } finally {
      setLoadingPaperInfo(false);
    }
  };

  const handleSubjectChange = async (e) => {
    const selectedSubjectId = e.target.value;
    setSelectedSubject(selectedSubjectId);

    if (selectedSubjectId && syllabusId && gradeId) {
      await fetchPaperTypes(selectedSubjectId);
    }
  };

  const handlePaperTypeChange = async (e) => {
    const selectedPaperTypeId = e.target.value;
    setSelectedPaperType(selectedPaperTypeId);

    if (selectedPaperTypeId && syllabusId && gradeId && selectedSubject) {
      await fetchPaperInfo(selectedPaperTypeId);
    }
  };

  const handleCreateGame = async () => {
    const userId = localStorage.getItem("UserID");
    const authToken = localStorage.getItem("authToken");
    const subjectId = selectedSubject;
    const paperTypeId = selectedPaperType;
    const paperInfoId = selectedPaperInfo;
    const levelNo = levelNumber;

    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/game/create?subjectId=${subjectId}&paperTypeId=${paperTypeId}&paperInfoId=${paperInfoId}&levelNo=${levelNo}&userId=${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setGameId(data.gameData.gameId);
        handleClose();
        setTimeout(() => {
          setShowOneVsOneStep3(true);
        }, 250);
      } else {
        console.error("Error creating game:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getSubjectNameById = (id) => {
    const subject = subjects.find((sub) => sub._id === id);
    return subject ? subject.name : "";
  };

  const getPaperTypeNameById = (id) => {
    const paperType = paperTypes.find((type) => type._id === id);
    return paperType ? paperType.paperTypeName : "";
  };

  const getPaperInfoNameById = (id) => {
    const Info = paperInfo.find((info) => info._id === id);
    return Info ? Info.paperInfo : "";
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modalPlay">
        <Modal.Header className="modalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="OneVsModelText">
            <span>1</span>
            <img src={VS} alt="vsLogo" />
            <span>1</span>
          </div>
          <div className="createGameTextOneVs">
            <span>Create the Game</span>
          </div>
          <div className="selectforStartTextOneVs">
            <span>Please select for start the game</span>
          </div>
          <form>
            <div>
              <div className="InputText-PlayModal">
                <span>Subject</span>
              </div>
              {loadingSubjects ? (
                <Spinner animation="border" />
              ) : (
                <Form.Select
                  aria-label="Select your subject"
                  value={selectedSubject}
                  onChange={handleSubjectChange}
                >
                  <option>Select your syllabus</option>
                  {subjects.map((subject) => (
                    <option key={subject._id} value={subject._id}>
                      {subject.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>

            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Paper Type</span>
              </div>
              {loadingPaperTypes ? (
                <Spinner animation="border" />
              ) : (
                <Form.Select
                  aria-label="Select your paperType"
                  value={selectedPaperType}
                  onChange={handlePaperTypeChange}
                >
                  <option>Select your paperType</option>
                  {paperTypes.map((paperType) => (
                    <option key={paperType._id} value={paperType._id}>
                      {paperType.paperTypeName}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>

            <div style={{ marginBottom: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Paper Info</span>
              </div>
              {loadingPaperInfo ? (
                <Spinner animation="border" />
              ) : (
                <Form.Select
                  aria-label="Select your paper info"
                  value={selectedPaperInfo}
                  onChange={(e) => setSelectedPaperInfo(e.target.value)}
                >
                  <option>Select your paper info</option>
                  {paperInfo.map((info) => (
                    <option key={info._id} value={info._id}>
                      {info.paperInfo}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>

            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className="InputText-PlayModal">
                <span>Level</span>
              </div>
              <Form.Select
                aria-label="Default select example"
                value={levelNumber}
                onChange={(e) => setLevelNumber(e.target.value)}
              >
                <option>Select the level</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Select>
            </div>
            <div className="modalStartBtn">
              <button type="button" onClick={handleCreateGame}>
                Create
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <OneVsOneStep3
        show={showOneVsOneStep3}
        handleClose={handleCloseCreateGame}
        gameId={gameId}
        selectedSubject={getSubjectNameById(selectedSubject)}
        selectedPaperType={getPaperTypeNameById(selectedPaperType)}
        selectedPaperInfo={getPaperInfoNameById(selectedPaperInfo)}
        levelNumber={levelNumber}
        subjectId={selectedSubject}
        paperTypeId={selectedPaperType}
        paperInfoId={selectedPaperInfo}
      />
    </div>
  );
}
