import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Level = ({ levelNumber, isLocked, onClick }) => {
  return (
    <div
      className={`level ${isLocked ? "locked" : "unlocked"}`}
      onClick={!isLocked ? onClick : null}
      style={{ cursor: isLocked ? "not-allowed" : "pointer" }}
    >
      <span className="lockIcon">
        {isLocked ? "🔒" : <span className="level-number">{levelNumber}</span>}
      </span>
    </div>
  );
};

Level.propTypes = {
  levelNumber: PropTypes.number.isRequired,
  isLocked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Level;
