import React from "react";
import AppleStore from "../assests/images/app-store.svg";
import GooglePlay from "../assests/images/googleplay.svg";
import "./style.css";
import { Image } from "react-bootstrap";

function MobileBlocker() {
  return (
    <div className="mobile-blocker">
      <div className="message-container">
        <p className="mobile-message">
          You are using a mobile device. For the best experience, please use the
          ExamHub mobile application.
        </p>
        <div className="store-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.fonixss.examhublk&hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="playstore-link"
          >
            <Image
              src={GooglePlay}
              alt="Google Play Store"
              className="store-icon"
            />
          </a>
          <a
            href="https://apps.apple.com/lk/app/examhub/id1567751663"
            target="_blank"
            rel="noopener noreferrer"
            className="appstore-link"
          >
            <Image
              src={AppleStore}
              alt="Apple App Store"
              className="store-icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileBlocker;
