import Login from "../src/component/login/index";
import SignUp1 from "../src/component/signup/index1";
import SignUp2 from "../src/component/signup/index2";
import FogotPassword1 from "../src/component/fogotPassword/index1";
import FogotPassword2 from "../src/component/fogotPassword/index2";
import Home from "../src/component/home/index";
import Setting from "../src/component/setting/index";
import LeaderBoard from "../src/component/leaderBoard/index";
import ChangeSyllabus from "../src/component/changeSyllabus/index";
import GameMap from "../src/component/gameLevel/GameMap";
import QuetionType1 from "../src/component/questionType/QuestionType1";
import VictoryThreeStars from "../src/component/victory/VictoryThreeStar";
import VictoryTwoStars from "../src/component/victory/VictoryTwoStar";
import VictoryOneStars from "../src/component/victory/VictoryOneStar";
import CorrectAnswers from "../src/component/answerPage/AnswerPage";
import OneVsOneStep4 from "../src/component/OneVsOne/OneVsOneStep4";
import TeamVsTeamStep6 from "../src/component/teamVsTeam/TeamVsTeamStep6";
import ChallangeYourSelf from "../src/component/questionType/ChallangeYourSelf";
import ChallangeAnswer from "../src/component/answerPage/Challange";
import OneVsOneQuestions from "../src/component/questionType/OneVsOneQuestion"
import OneVsOneThreeStar from '../src/component/victory/OneVsOneThreeStar'
import OneVsOneOpps from '../src/component/victory/OneVsOneOpps'
import OneVsOneAnswerPage from "../src/component/answerPage/OneVsOne"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileBlocker from "../src/component/mobileBlocker/MobileBlocker";
import "./App.css";

function App() {
  if (isMobile) {
    return <MobileBlocker />;
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup-step-1" element={<SignUp1 />} />
          <Route path="/signup-step-2" element={<SignUp2 />} />
          <Route path="/fogotpassword-step-1" element={<FogotPassword1 />} />
          <Route path="/fogotpassword-step-2" element={<FogotPassword2 />} />
          <Route path="/home" element={<Home />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/change-syllabus" element={<ChangeSyllabus />} />
          <Route path="/game-map" element={<GameMap />} />
          <Route path="/question-type-1" element={<QuetionType1 />} />
          <Route path="/victory-three-stars" element={<VictoryThreeStars />} />
          <Route path="/victory-two-stars" element={<VictoryTwoStars />} />
          <Route path="/victory-one-stars" element={<VictoryOneStars />} />
          <Route path="/correct-answer" element={<CorrectAnswers />} />
          <Route path="/one-vs-one-step-4" element={<OneVsOneStep4 />} />
          <Route path="/team-vs-team-step-6" element={<TeamVsTeamStep6 />} />
          <Route path="/challange-your-self" element={<ChallangeYourSelf />} />
          <Route path="/challange-your-self-answer" element={<ChallangeAnswer />}/>
          <Route path="/one-vs-one-questions" element={<OneVsOneQuestions />}/>
          <Route path="/one-vs-one-victory" element={<OneVsOneThreeStar />}/>
          <Route path="/one-vs-one-lost" element={<OneVsOneOpps />}/>
          <Route path="/one-vs-one-answer" element={<OneVsOneAnswerPage />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
