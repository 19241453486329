import React from "react";
import { Modal, Spinner, Image } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";

export default function TeamVsTeamJoinGame2({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} className="modalPlay">
      <Modal.Header className="modalHeader" closeButton></Modal.Header>
      <Modal.Body>
        <div className="teamVsModal">
          <Image src={Team} alt="vsLogo" fluid rounded />
          <Image src={VS} alt="vsLogo" fluid rounded />
          <Image src={Team} alt="vsLogo" fluid rounded />
        </div>
        <div className="beReadyText">
          <span>Be Ready !</span>
        </div>
        <div className="gameWillStartText">
          <span>Game will start soon</span>
        </div>
        <div className="spinnerAnimation">
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="pleseWaitText">
          <span>Please wait .. </span>
          <span>Until your friend start the game</span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
