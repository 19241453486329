import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Image, Form, ProgressBar } from "react-bootstrap";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import Clock from "../assests/images/clock.gif";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";
import Config from "../../config";
import "./style.css";

export default function QuestionType1() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    syllabusId,
    mediumId,
    gradeId,
    subject,
    paperType,
    paperInfo,
    levelNumber,
  } = location.state;

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const totalQuestions = questions.length;

  const progressPercentage =
    totalQuestions > 0
      ? ((currentQuestionIndex + 1) / totalQuestions) * 100
      : 0;

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `${Config.API_URL}/api/v1/mobile/level/questions?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}&subjectId=${subject}&paperTypeId=${paperType}&paperInfoId=${paperInfo}&levelNo=${levelNumber}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch questions");
        }

        const result = await response.json();
        setQuestions(result.levelData.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [
    syllabusId,
    mediumId,
    gradeId,
    subject,
    paperType,
    paperInfo,
    levelNumber,
  ]);

  useEffect(() => {
    let timer;

    if (!isQuizCompleted) {
      timer = setInterval(() => {
        setTotalTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isQuizCompleted]);

  const handleAnswerSelection = (answer) => {
    setSelectedAnswer(answer);

    setUserAnswers((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[currentQuestionIndex] = answer;
      return updatedAnswers;
    });

    setCorrectAnswersCount((prevCount) =>
      answer === questions[currentQuestionIndex].answer
        ? prevCount + 1
        : prevCount
    );

    setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedAnswer(null);
      } else {
        setIsQuizCompleted(true);
        saveQuizResults();
        navigateToVictoryPage();
      }
    }, 1000);
  };

  useEffect(() => {
    console.log("Updated correctAnswersCount:", correctAnswersCount);
  }, [correctAnswersCount]);

  const sanitizeHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.innerText || tempElement.textContent;
  };

  const saveQuizResults = async () => {
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("UserID");

    const data = {
      syllabusId: syllabusId,
      mediumId: mediumId,
      gradeId: gradeId,
      subjectId: subject,
      paperTypeId: paperType,
      paperInfoId: paperInfo,
      userId: userId,
      levelNo: levelNumber,
      levelStatus: "completed",
      points: correctAnswersCount,
    };

    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/level/complete-save?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}&subjectId=${subject}&paperTypeId=${paperType}&paperInfoId=${paperInfo}&userId=${userId}&levelNo=${levelNumber}&levelStatus=completed&points=${correctAnswersCount}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save quiz results");
      }

      const result = await response.json();
      console.log("Quiz results saved successfully:", result);
    } catch (error) {
      console.error("Error saving quiz results:", error);
    }
  };

  const navigateToVictoryPage = () => {
    const scorePercentage = (correctAnswersCount / totalQuestions) * 100;
    const wrongAnswersCount = totalQuestions - correctAnswersCount;

    let victoryPath;

    if (scorePercentage >= 75) {
      victoryPath = "/victory-three-stars";
    } else if (scorePercentage < 75 && scorePercentage >= 50) {
      victoryPath = "/victory-two-stars";
    } else {
      victoryPath = "/victory-one-stars";
    }

    navigate(victoryPath, {
      state: {
        totalTime,
        correctAnswersCount,
        wrongAnswersCount,
        userAnswers,
        questions,
        syllabusId,
        mediumId,
        gradeId,
        subject,
        paperType,
        paperInfo,
        levelNumber,
        from: "/question-type-1",
      },
    });
  };

  const renderQuestionType = () => {
    const question = questions[currentQuestionIndex];
    const imageBaseURL = `${Config.API_URL}/api/v1/uploads/`;

    if (!question) {
      return <div>Loading question...</div>;
    }

    switch (question.questionType) {
      case "truefalse":
        return (
          <div className="AnswerSection">
            <div className="TextAnswerSection">
              {["True", "False"].map((option, index) => (
                <span className="answersText">
                  <Form.Check
                    key={index}
                    type="radio"
                    name="answer"
                    checked={selectedAnswer === option}
                    className="custom-radio"
                    onChange={() => handleAnswerSelection(option)}
                  />
                  {option}
                </span>
              ))}
            </div>
          </div>
        );

      case "imageanswer":
        return (
          <div className="AnswerSectionQT3">
            <div className="ImageAnswerSection">
              <Image
                src={`${imageBaseURL}${question.questionImage}`}
                fluid
                alt="Question image"
              />
            </div>
            <div className="TextAnswerSection grid">
              {[
                question.optionA,
                question.optionB,
                question.optionC,
                question.optionD,
                question.optionE,
              ].map((src, index) => {
                const option = String.fromCharCode(65 + index);
                return (
                  <div
                    key={index}
                    className={`image-option ${
                      selectedAnswer === option ? "selected" : ""
                    }`}
                    onClick={() => handleAnswerSelection(option)}
                  >
                    <Image
                      src={`${imageBaseURL}${src}`}
                      fluid
                      alt={`Option ${option}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );

      case "image":
        return (
          <div className="AnswerSection">
            <div className="ImageAnswerSection">
              <Image src={`${imageBaseURL}${question.questionImage}`} fluid />
            </div>
            <div className="TextAnswerSection">
              {["A", "B", "C", "D", "E"].map((option, index) => (
                <span key={index} className="answersText">
                  <Form.Check
                    type="radio"
                    name="answer"
                    className="custom-radio"
                    checked={selectedAnswer === option}
                    onChange={() => handleAnswerSelection(option)}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {sanitizeHTML(question[`option${option}`])}
                </span>
              ))}
            </div>
          </div>
        );

      case "textonly":
      default:
        return (
          <div className="answer">
            {["A", "B", "C", "D", "E"].map((option, index) => (
              <span key={index} className="answersText">
                <Form.Check
                  type="radio"
                  name="answer"
                  className="custom-radio"
                  checked={selectedAnswer === option}
                  onChange={() => handleAnswerSelection(option)}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {sanitizeHTML(question[`option${option}`])}
              </span>
            ))}
          </div>
        );
    }
  };

  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <NavName />
          <Container>
            <div className="QuestionType1Content">
              <div className="timeAndProgress">
                <div className="time">
                  <Image className="clock" src={Clock} alt="clock" fluid />
                  <span> {totalTime} seconds</span>
                </div>
                <div className="progressBar">
                  <div className="noOfQuestion_AP">
                    <span>
                      Question {currentQuestionIndex + 1} of {totalQuestions}
                    </span>
                  </div>
                  <ProgressBar
                    now={progressPercentage}
                    className="custom-progress-bar"
                  />
                </div>
              </div>
              <div className="question">
                <span>
                  {sanitizeHTML(
                    questions[currentQuestionIndex]?.questionTitle ||
                      "Loading question..."
                  )}
                </span>
              </div>
              <div className="QuestionPart">{renderQuestionType()}</div>
              {isQuizCompleted && (
                <div>
                  <span>Correct Answers: {correctAnswersCount}</span>
                  <span> Total Time Taken: {totalTime} seconds</span>
                </div>
              )}
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
