import React, { useState } from "react";
import Config from "../../config/index";
import LoginLogo from "../assests/images/loginLogo.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import { Container, Image, Form, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function Index() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, otpCode } = location.state || {};

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(
        `${Config.API_URL}/api/v2/mobile/user/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            otp: otpCode,
            newPassword,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setError(data.message || "Failed to reset password.");
      }
    } catch (error) {
      setError("Something went wrong! Please try again.");
    }
  };
  return (
    <div className="LoginContainer">
      <Container>
        <div className="LeftCloud">
          {" "}
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          {" "}
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          {" "}
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="loginForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>
            <div className="Welcometext">
              <span>Account Recovery</span>
            </div>
            <div className="welcomesubtext">
              <span>Enter your account email to verify</span>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            {success && (
              <Alert variant="success">Password Reset Successfully!</Alert>
            )}

            <div className="inputfield">
              <div className="InputText">
                <span>Password</span>
              </div>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
            </div>

            <div className="inputfield">
              <div className="InputText">
                <span>Confirm Password</span>
              </div>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="SignInBtn" style={{ marginTop: "100px" }}>
              <button onClick={handleSubmit}>Done</button>
            </div>
          </div>
        </center>
        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>
      <div className="Bottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
