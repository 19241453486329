import React, { useState, useEffect } from "react";
import "./style.css";
import { Container, Image } from "react-bootstrap";
import Plus from "../assests/images/plus.svg";
import Star from "../assests/images/start.svg";
import Config from "../../config/index";

export default function Index() {
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState({
    country: "Sri Lanka",
    medium: "Sinhala",
    grade: "Grade 10",
  });

  useEffect(() => {
    const storedName = localStorage.getItem("UserName");
    const userId = localStorage.getItem("UserID");
    const token = localStorage.getItem("authToken");

    if (storedName) {
      setUserName(storedName);
    } else {
      setUserName("Guest");
    }

    if (userId && token) {
      fetch(`${Config.API_URL}/api/v1/mobile/user/details/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const { country, medium, grade, totalPoints } = data;
          setUserDetails({
            country: country || "Sri Lanka",
            medium: medium.name || "Sinhala",
            grade: grade.name || "Grade 10",
            totalPoints: totalPoints || "0",
          });
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }
  }, []);

  console.log("userDetails", userDetails);

  return (
    <Container>
      <div className="welcomeContainer">
        <div className="welcomeName">
          <span>Hi {userName} , Welcome Back!</span>
        </div>
        <div className="path">
          <span>
            {userDetails.country} {">"} {userDetails.medium} {">"}{" "}
            {userDetails.grade}
          </span>
        </div>
        <div className="pointsDisplay">
          <div className="starIcon">
            <Image src={Star} alt="Star Icon" fluid />
          </div>
          <div className="pointLine">
            <span>{userDetails.totalPoints}</span>
          </div>
          &nbsp;&nbsp;
          <div className="plusIcon">
            <Image src={Plus} alt="Plus Icon" />
          </div>
        </div>
      </div>
    </Container>
  );
}
