import React from "react";
import SidebarLayout from "../sidebarMenu/index";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import { Container, Image } from "react-bootstrap";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";

export default function TeamVsTeamStep6() {
  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            {" "}
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            {" "}
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            {" "}
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <NavName />
          <Container>
            <div className="Step4Container">
              <div className="leftSideCard">
                <div className="topTextSectionStep-4">
                  <div className="readyToGo">
                    <span>Ready to go !</span>
                  </div>
                  <div className="clickStartButtonText">
                    <span>
                      Click start button to start the game with your friend
                    </span>
                  </div>
                  <div className="oneVsOnePlayerNameSection"></div>
                  <div className="OneVsOneStartButton">
                    <button>Start</button>
                  </div>
                </div>
              </div>
              <div className="rightSideCard">
                <div className="SelectedSyllabusText">
                  <span>Selected syllabus</span>
                </div>
                <div className="selectedSyllabusForm">
                  <form className="OneVsOneForm">
                    <div className="fieldName">
                      <span>Subject</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select your subject"
                      />
                    </div>

                    <div className="fieldName">
                      <span>Paper Type</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select your paper type"
                      />
                    </div>

                    <div className="fieldName">
                      <span>Paper</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select the paper"
                      />
                    </div>

                    <div className="fieldName">
                      <span>Level</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select the level"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
