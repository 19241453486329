import React, { useEffect, useState } from "react";
import LoginLogo from "../assests/images/loginLogo.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import { Container, Image, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Config from "../../config/index";
import "./style.css";

export default function Index() {
  const [syllabusList, setSyllabusList] = useState([]);
  const [mediumList, setMediumList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedSyllabus, setSelectedSyllabus] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [userName, setUserName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedName = localStorage.getItem("UserName");
    if (storedName) {
      setUserName(storedName);
    } else {
      setUserName("Guest");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      fetch(`${Config.API_URL}/api/v1/syllabus`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Authorization header is missing or invalid!");
          }
          return response.json();
        })
        .then((data) => {
          if (data && data.syllabuses) {
            setSyllabusList(data.syllabuses);
          }
        })
        .catch((error) => console.error("Error fetching syllabus:", error));
    } else {
      console.error("Authorization token is missing!");
    }
  }, []);

  useEffect(() => {
    if (selectedSyllabus) {
      const token = localStorage.getItem("authToken");
      fetch(
        `${Config.API_URL}/api/v1/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.mediums) {
            setMediumList(data.mediums);
          }
        })
        .catch((error) => console.error("Error fetching mediums:", error));
    }
  }, [selectedSyllabus]);

  useEffect(() => {
    if (selectedSyllabus) {
      const token = localStorage.getItem("authToken");
      fetch(
        `${Config.API_URL}/api/v1/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.grades) {
            setGradeList(data.grades);
          }
        })
        .catch((error) => console.error("Error fetching grades:", error));
    }
  }, [selectedSyllabus]);

  const handleSubmit = () => {
    const userId = localStorage.getItem("UserID");
    const token = localStorage.getItem("authToken");

    if (userId && selectedSyllabus && selectedMedium && selectedGrade) {
      setLoading(true);
      fetch(
        `${Config.API_URL}/api/v1/mobile/user/update-syllabus-medium-grade`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            syllabusId: selectedSyllabus,
            mediumId: selectedMedium,
            gradeId: selectedGrade,
          }),
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to update");
          }
          return response.json();
        })
        .then((data) => {
          setAlertMessage("Updated successfully!");
          setShowAlert(true);
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        })
        .catch((error) => {
          console.error("Error:", error);
          setAlertMessage("Error: Unable to update the information.");
          setShowAlert(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setAlertMessage("Please fill all the fields.");
      setShowAlert(true);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="LoginContainer">
      <Container>
        <div className="LeftCloud">
          {" "}
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          {" "}
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          {" "}
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="loginForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>
            <div className="Welcometext">
              <span>Welcome!</span>
            </div>
            <div className="welcomesubtext">
              <span>{userName}</span>
            </div>
            <div className="changeSyllabusText">
              <span>Please select the syllabus.</span>
            </div>
            {showAlert && <Alert variant="info">{alertMessage}</Alert>}
            <div>
              <div className="InputText">
                <span>Syllabus</span>
              </div>
              <Form.Select
                aria-label="Default select example"
                value={selectedSyllabus}
                onChange={(e) => setSelectedSyllabus(e.target.value)}
              >
                <option>Select your syllabus</option>
                {syllabusList.map((syllabus) => (
                  <option key={syllabus._id} value={syllabus._id}>
                    {syllabus.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div>
              <div className="InputText">
                <span>Medium</span>
              </div>
              <Form.Select
                aria-label="Default select example"
                value={selectedMedium}
                onChange={(e) => setSelectedMedium(e.target.value)}
              >
                <option>Select your medium of syllabus</option>
                {mediumList.map((medium) => (
                  <option key={medium._id} value={medium._id}>
                    {medium.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div>
              <div className="InputText">
                <span>Grade</span>
              </div>
              <Form.Select
                aria-label="Default select example"
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
              >
                <option>Select your grade</option>
                {gradeList.map((grade) => (
                  <option key={grade._id} value={grade._id}>
                    {grade.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="StartBtn">
              <button onClick={handleSubmit} disabled={loading}>
                {loading ? "Loading..." : "Start"}
              </button>
            </div>
            <div className="checkAnswerBtn" style={{ marginTop: "4%" }}>
              <button style={{ width: "100%" }} onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </center>
        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>
      <div className="Bottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
