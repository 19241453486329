import React, { useState, useEffect } from "react";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Image, Spinner } from "react-bootstrap";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import LeaderBoard from "../assests/images/leaderBoardName.svg";
import PlaceOne from "../assests/images/1st.svg";
import PlaceTwo from "../assests/images/2nd.svg";
import PlaceThree from "../assests/images/3rd.svg";
import Stars from "../assests/images/stars.svg";
import ProfilePic1 from "../assests/images/proPic1.svg";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";
import Config from "../../config";
import "./style.css";

export default function Index() {
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [timeRange, setTimeRange] = useState("all");
  const [loading, setLoading] = useState(false);

  const fetchLeaderboard = async (range) => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        throw new Error("Missing user credentials");
      }

      const userDetailsResponse = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const userDetails = await userDetailsResponse.json();

      const { grade, syllabus } = userDetails;
      const { _id: gradeId } = grade;
      const { _id: syllabusId } = syllabus;

      let url = `${Config.API_URL}/api/v1/mobile/level/points/all?syllabusId=${syllabusId}&gradeId=${gradeId}&userId=${userId}`;

      if (range === "daily") {
        url = `${Config.API_URL}/api/v1/mobile/level/points/daily?syllabusId=${syllabusId}&gradeId=${gradeId}&userId=${userId}`;
      } else if (range === "weekly") {
        url = `${Config.API_URL}/api/v1/mobile/level/points/weekly?syllabusId=${syllabusId}&gradeId=${gradeId}&userId=${userId}`;
      } else if (range === "monthly") {
        url = `${Config.API_URL}/api/v1/mobile/level/points/monthly?syllabusId=${syllabusId}&gradeId=${gradeId}&userId=${userId}`;
      }

      const leaderboardResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const leaderboard = await leaderboardResponse.json();

      setLeaderboardData(leaderboard);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard(timeRange);
  }, [timeRange]);

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  if (loading) {
    return (
      <SidebarLayout>
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="LeaderBoardContent">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </Container>
      </SidebarLayout>
    );
  }

  if (
    !leaderboardData ||
    !leaderboardData.leaderboard ||
    leaderboardData.leaderboard.length === 0
  ) {
    return (
      <SidebarLayout>
        <Container>
          <div className="leaderBoard">
            <h6 className="text">
              No leaderboard data available at this moment.
            </h6>
          </div>
        </Container>
      </SidebarLayout>
    );
  }

  const { leaderboard } = leaderboardData;

  return (
    <SidebarLayout>
      <Container>
        <div className="LeaderBoardContent">
          <WelComeSection />
          <NavName />
          <div className="leaderBoardFrame">
            <div className="timeSection">
              <div
                className={`WeeklyandYearly ${
                  timeRange === "daily" ? "active" : ""
                }`}
                onClick={() => handleTimeRangeChange("daily")}
              >
                <span>Daily</span>
              </div>
              <div
                className={`Monthly ${timeRange === "weekly" ? "active" : ""}`}
                onClick={() => handleTimeRangeChange("weekly")}
              >
                <span>Weekly</span>
              </div>
              <div
                className={`WeeklyandYearly ${
                  timeRange === "monthly" ? "active" : ""
                }`}
                onClick={() => handleTimeRangeChange("monthly")}
              >
                <span>Monthly</span>
              </div>
            </div>
            <div className="leaderBoard">
              <div className="leaderBoardImg">
                <Image src={LeaderBoard} alt="image" fluid />
              </div>

              {/* Top 3 Players */}
              <div className="medalSection">
                {leaderboard.slice(0, 3).map((player, index) => (
                  <div key={player.userId}>
                    <Image
                      className={index === 1 ? "sideTwoImg" : ""}
                      src={
                        index === 0
                          ? PlaceOne
                          : index === 1
                          ? PlaceTwo
                          : PlaceThree
                      }
                      alt="place"
                      fluid
                    />
                    <div className="NameInWinners">
                      <span>{player.name}</span>
                    </div>
                    <div className="scoreSection">
                      <span>{player.totalPoints}</span>&nbsp;
                      <Image src={Stars} alt="stars" fluid />
                    </div>
                  </div>
                ))}
              </div>

              {/* Remaining Players */}
              <div className="nameSection-leaderBoard">
                {leaderboard.slice(3).map((player, index) => (
                  <div className="winnerScoreName" key={player.userId}>
                    <div className="Place">
                      <span>{index + 4}</span>
                    </div>
                    <div className="ProfilePicture-leaderBoard">
                      <Image
                        src={
                          player.profilePicture
                            ? `${Config.API_URL}/api/v1/uploads/profile-pictures/${player.profilePicture}`
                            : ProfilePic1
                        }
                        alt="profilePic"
                        fluid
                        rounded
                        className="ProfilePictureLeaderBoard"
                      />
                    </div>
                    <div className="PlayerName-leaderBoard">
                      <span>{player.name}</span>
                    </div>
                    <div className="NoOfpoint">
                      <span>
                        {player.totalPoints} &nbsp;
                        <Image src={Stars} alt="StartMark" fluid />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <div className="leaderBoardContainer">
        <div className="backgroundSection">
          <Container>
            <div className="LeftCloud">
              <Image src={LeftCloud} alt="Cloud" fluid />
            </div>
            <div className="Sun">
              <Image src={Sun} alt="Sun" fluid />
            </div>
            <div className="RightCloud">
              <Image src={RightCloud} alt="Cloud" fluid />
            </div>
          </Container>
        </div>
      </div>

      <div className="BottomSection">
        <div className="RightAnimal">
          <Image
            className="RightAnimal-LeaderBoard"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="LeftGrasses" src={GrassLeft} alt="logo" fluid />
        <Image className="RightGrasses" src={GrassRight} alt="logo" fluid />
        <Image
          style={{ display: "inline-block", width: "100%" }}
          src={BottomImg}
          alt="logo"
          fluid
        />
      </div>
    </SidebarLayout>
  );
}
