import React from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { Container } from "react-bootstrap";
import AppName from "../assests/images/navAppName.svg";
import SettingAnimation from "../assests/images/setting.gif";
import LeaderBoarAnimation from "../assests/images/leaderBoard.gif";
import PageAnimaion from "../assests/images/LevelNumber.svg";
import OneVsOne from "../assests/images/1vs1.svg";
import TeamVsTeam from "../assests/images/teamVsTeam.svg";

export default function Index() {
  const location = useLocation();

  const getPageName = (pathname) => {
    switch (pathname) {
      case "/home":
        return "Home";
      case "/setting":
        return "Settings";
      case "/change-syllabus":
        return "Change Syllabus";
      case "/leaderboard":
        return "Leaderboard";
      case "/correct-answer":
        return "Correct Answers";
      case "/one-vs-one-step-4":
        return "1 vs 1 Game";
      case "/team-vs-team-step-6":
        return "Team vs Team Game";
      default:
        return "Page";
    }
  };

  const pageName = getPageName(location.pathname);

  const getAnimation = (pageName) => {
    if (pageName === "Settings") {
      return <img src={SettingAnimation} alt="Settings Animation" />;
    } else if (pageName === "Leaderboard") {
      return <img src={LeaderBoarAnimation} alt="Leaderboard Animation" />;
    } else if (pageName === "Correct Answers") {
      return <img src={PageAnimaion} alt="pageAnimation" />;
    } else if (pageName === "1 vs 1 Game") {
      return <img src={OneVsOne} alt="One Vs One" />;
    } else if (pageName === "Team vs Team Game") {
      return <img src={TeamVsTeam} alt="One Vs One" />;
    }
    return null;
  };

  return (
    <Container>
      <div className="navNameContainer">
        <div className="MenuName">
          <div className="MenuNameDiv">
            <span className="nameInMenu">{getPageName(location.pathname)}</span>
            <span className="nameInpath">
              Home . {getPageName(location.pathname)}
            </span>
          </div>
        </div>
        <div className="navappName">
          <img src={AppName} alt="logo" />
        </div>
        <div className="icon">
          <div className="settingAnimation">{getAnimation(pageName)}</div>
        </div>
      </div>
    </Container>
  );
}
