import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import Config from "../../config";

const socket = io(`${Config.API_URL}`);

export default function OneVsOneJoinGame2({ show, handleClose, gameData }) {
  const [loading, setLoading] = useState(true);
  const [playerCount, setPlayerCount] = useState(0);
  const navigate = useNavigate();

  const subjectId = gameData?.subjectId || "";
  const paperTypeId = gameData?.paperTypeId || "";
  const paperInfoId = gameData?.paperInfoId || "";
  const levelNumber = gameData?.levelNo || "";
  const gameId = gameData?.gameId || "";
  const playerName = gameData?.hostName || "";

  useEffect(() => {
    const userId = localStorage.getItem("UserID");

    socket.emit("join", { userId });

    socket.on("game_started", (data) => {
      setPlayerCount((prevCount) => prevCount + 1);

      if (playerCount >= 1) {
        setLoading(false);
      }
      navigate("/one-vs-one-questions", {
        state: {
          subjectId,
          paperInfoId,
          paperTypeId,
          levelNumber,
          gameId,
          playerName,
        },
      });

      if (!data.userId === 0) {
        setLoading(false);
      }
    });

    return () => {
      socket.off("game_started");
    };
  }, [
    subjectId,
    paperInfoId,
    paperTypeId,
    levelNumber,
    playerCount,
    gameId,
    playerName,
    navigate,
  ]);

  return (
    <Modal show={show} onHide={handleClose} className="modalPlay">
      <Modal.Header className="modalHeader" closeButton></Modal.Header>
      <Modal.Body>
        <div className="OneVsModelText">
          <span>1</span>
          <img src={VS} alt="vsLogo" />
          <span>1</span>
        </div>
        <div className="beReadyText">
          <span>Be Ready !</span>
        </div>
        <div className="gameWillStartText">
          <span>Game will start soon</span>
        </div>
        <div className="spinnerAnimation">
          {loading && (
            <div className="spinnerAnimation">
              <Spinner animation="border" role="status" className="spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
        <div className="pleseWaitText">
          <span>Please wait .. </span>
          <span>Until your friend start the game</span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
