import React, { useState, useEffect } from "react";
import "./style.css";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Form, Alert, Spinner } from "react-bootstrap";
import EditIcon from "../assests/images/editBtn.svg";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";
import { NavLink } from "react-router-dom";
import Config from "../../config";

export default function Index() {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    secondName: "",
    email: "",
    phone: "",
    country: "Sri Lanka",
    profilePicture: null,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const [profilePic, setProfilePic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const userId = localStorage.getItem("UserID");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    if (userId && token) {
      fetch(`${Config.API_URL}/api/v1/mobile/user/details/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const fullName = data.name.split(" ");
          setUserDetails({
            firstName: fullName[0] || "",
            secondName: fullName.slice(1).join(" ") || "",
            email: data.email || "",
            phone: data.phone || "",
            country: data.country || "Sri Lanka",
            profilePicture: data.profilePicture,
          });
          if (data.profilePicture) {
            setProfilePic(
              `${Config.API_URL}/api/v1/uploads/profile-pictures/${data.profilePicture}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const { firstName, secondName, email, phone } = userDetails;
    const updatedData = {
      name: `${firstName} ${secondName}`,
      email,
      phone,
    };

    if (userId && token) {
      fetch(`${Config.API_URL}/api/v2/mobile/user/update/${userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      })
        .then((response) => response.json())
        .then((data) => {
          setAlertMessage("User details updated successfully!");
          setAlertVariant("success");
          setShowAlert(true);
        })
        .catch((error) => {
          setAlertMessage("Error updating user details. Please try again.");
          setAlertVariant("danger");
          setShowAlert(true);
        });
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);

      fetch(`${Config.API_URL}/api/v2/mobile/user/update/${userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setProfilePic(URL.createObjectURL(file));
          setAlertMessage("Profile picture updated successfully!");
          setAlertVariant("success");
          setShowAlert(true);
        })
        .catch((error) => {
          setAlertMessage("Error updating profile picture. Please try again.");
          setAlertVariant("danger");
          setShowAlert(true);
        });
    }
  };

  const handleChangePassword = () => {
    const passwordData = {
      currentPassword,
      newPassword,
      confirmPassword,
    };

    if (userId && token) {
      fetch(`${Config.API_URL}/api/v2/mobile/user/change-pw/${userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passwordData),
      })
        .then((response) => response.json())
        .then((data) => {
          setAlertMessage("Password changed successfully!");
          setAlertVariant("success");
          setShowAlert(true);
        })
        .catch((error) => {
          setAlertMessage("Error changing password. Please try again.");
          setAlertVariant("danger");
          setShowAlert(true);
        });
    }
  };

  return (
    <SidebarLayout>
      <div className="setttingContainer">
        <div>
          <WelComeSection />
          <NavName />
        </div>
        <Container className="containerFrame">
          {showAlert && (
            <Alert
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              {alertMessage}
            </Alert>
          )}
          <div className="topcardSection">
            <div className="settingLeftsideCard">
              <div className="yourProfile">
                <span>Your Profile</span>
              </div>
              <div className="changeYourProfile">
                <span>Change your profile picture from here</span>
              </div>

              <div className="ProfilePicture">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  profilePic && (
                    <img
                      src={profilePic}
                      alt="profile"
                      className="ProfilePictureImg"
                    />
                  )
                )}
                <div className="editIcon">
                  <label htmlFor="profilePicInput">
                    <img src={EditIcon} alt="Edit" />
                  </label>
                  <input
                    id="profilePicInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleProfilePicChange}
                  />
                </div>
              </div>
              <div className="resetBtn">
                <button onClick={() => setProfilePic(null)}>Reset</button>
              </div>
              <div className="allowText">
                <span>Allowed JPG and PNG. Max size of 800K</span>
              </div>
            </div>

            <div className="settingRightsideCard">
              <div className="yourProfile">
                <span>Change Password</span>
              </div>
              <div className="changeYourPassword">
                <span>To change your password please confirm here</span>
              </div>
              <form
                className="formSettings"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleChangePassword();
                }}
              >
                <div className="inputfield">
                  <div className="InputText-Setting">
                    <span>Current Password</span>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </Form.Group>
                </div>

                <div className="inputfield">
                  <div className="InputText-Setting">
                    <span>New Password</span>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="inputfield">
                  <div className="InputText-Setting">
                    <span>Confirm Password</span>
                  </div>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="saveBtn">
                  <button type="submit">Save</button>
                </div>
              </form>
            </div>
          </div>
          <div className="settingBottomSection">
            <div className="changePersonalData">
              <span>
                To change your personal detail , edit and save from here
              </span>
            </div>

            {showAlert && (
              <Alert
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}

            <div className="nameSection">
              <div className="firstname">
                <div className="InputText-Setting-Bottom">
                  <span>First Name</span>
                </div>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name here"
                  value={userDetails.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="secondname">
                <div className="InputText-Setting-Bottom">
                  <span>Second Name</span>
                </div>
                <Form.Control
                  type="text"
                  name="secondName"
                  placeholder="Enter your first name here"
                  value={userDetails.secondName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="emailSection">
              <div className="firstname">
                <div className="InputText-Setting-Bottom">
                  <span>Email Address</span>
                </div>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email address here"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="secondname">
                <div className="InputText-Setting-Bottom">
                  <span>Phone Number</span>
                </div>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Enter your phone number here"
                  value={userDetails.phone}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>

            <div className="countrySection">
              <div className="InputText-Setting-Bottom">
                <span>
                  Country{" "}
                  <span className="cannotBe"> (cannot be change here)</span>
                </span>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter your country here"
                name="country"
                value={userDetails.country}
                disabled
              />
            </div>
            <div className="saveBtn-Bottom">
              <button onClick={handleSave}>Save</button>
            </div>

            <div className="wanttoChangeDiv">
              <span className="wanttoChange">Want to change the Syllabus</span>
              &nbsp;&nbsp;
              <NavLink to="/change-syllabus" style={{ textDecoration: "none" }}>
                <span className="clickHere">Click Here</span>
              </NavLink>
            </div>
          </div>
        </Container>
      </div>
    </SidebarLayout>
  );
}
