import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Image, Form, ProgressBar } from "react-bootstrap";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";
import Config from "../../config";
import "./style.css";

export default function AnswerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userAnswers, subject, paperType, paperInfo, noOfQuestions } =
    location.state || {};

  const [fetchedQuestions, setFetchedQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      const url = `${Config.API_URL}/api/v1/mobile/common/questions`;

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subjectId: subject,
            paperTypeId: paperType,
            paperInfoId: paperInfo,
            noOfQuestions: noOfQuestions,
          }),
        });

        const data = await response.json();
        setFetchedQuestions(data.questions || []);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [userAnswers, subject, paperType, paperInfo, noOfQuestions]);

  const handleNext = () => {
    if (currentQuestionIndex < fetchedQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      navigate("/home");
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const currentQuestion = fetchedQuestions[currentQuestionIndex];
  const progressPercentage =
    fetchedQuestions.length > 0
      ? ((currentQuestionIndex + 1) / fetchedQuestions.length) * 100
      : 0;

  const sanitizeHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.innerText || tempElement.textContent;
  };

  const renderQuestionType = () => {
    const imageBaseURL = `${Config.API_URL}/api/v1/uploads/`;

    if (!currentQuestion) {
      return (
        <div className="loadingText">
          <span>Loading question...</span>
        </div>
      );
    }

    const correctAnswer = currentQuestion.answer;
    const userAnswer = userAnswers[currentQuestionIndex];

    switch (currentQuestion.questionType) {
      case "truefalse":
        return (
          <>
            <div className="question">
              <span>{sanitizeHTML(currentQuestion.questionTitle)}</span>
            </div>
            <div className="AnswerSection">
              <div className="TextAnswerSection">
                {["True", "False"].map((option, index) => {
                  const isUserAnswer = userAnswer === option;
                  const isCorrect = option === correctAnswer;

                  return (
                    <span className="answersText" key={index}>
                      <Form.Check
                        type="radio"
                        name="answer"
                        checked={isUserAnswer}
                        className="custom-radio"
                        readOnly
                      />
                      {option}
                      {isUserAnswer && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: isCorrect ? "green" : "red",
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          {isCorrect ? "(Correct)" : "(Incorrect)"}
                        </span>
                      )}
                      {!isUserAnswer && isCorrect && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                          }}
                        >
                          (Correct)
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
          </>
        );

      case "imageanswer":
        return (
          <>
            <div className="question">
              <span>{sanitizeHTML(currentQuestion.questionTitle)}</span>
            </div>
            <div className="AnswerSectionQT3">
              <div className="ImageAnswerSection">
                <Image
                  src={`${imageBaseURL}${currentQuestion.questionImage}`}
                  fluid
                  alt="Question image"
                />
              </div>
              <div className="TextAnswerSection grid">
                {[
                  currentQuestion.optionA,
                  currentQuestion.optionB,
                  currentQuestion.optionC,
                  currentQuestion.optionD,
                  currentQuestion.optionE,
                ].map((src, index) => {
                  const optionLetter = String.fromCharCode(65 + index);
                  const isUserAnswer = userAnswer === optionLetter;
                  const isCorrect = optionLetter === correctAnswer;

                  return (
                    <div
                      key={index}
                      className={`image-option ${
                        isUserAnswer ? "selected" : ""
                      }`}
                    >
                      <Image
                        src={`${imageBaseURL}${src}`}
                        fluid
                        alt={`Option ${optionLetter}`}
                      />
                      {isUserAnswer && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: isCorrect ? "green" : "red",
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          {isCorrect ? "(Correct)" : "(Incorrect)"}
                        </span>
                      )}
                      {!isUserAnswer && isCorrect && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                          }}
                        >
                          (Correct)
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );

      case "image":
        return (
          <>
            <div className="question">
              <span>{sanitizeHTML(currentQuestion.questionTitle)}</span>
            </div>
            <div className="AnswerSection">
              <div className="ImageAnswerSection">
                <Image
                  src={`${imageBaseURL}${currentQuestion.questionImage}`}
                  fluid
                />
              </div>
              <div className="TextAnswerSection">
                {["A", "B", "C", "D", "E"].map((option, index) => {
                  const isUserAnswer = userAnswer === option;
                  const isCorrect = option === correctAnswer;

                  return (
                    <span key={index} className="answersText">
                      <Form.Check
                        type="radio"
                        name="answer"
                        className="custom-radio"
                        checked={isUserAnswer}
                        readOnly
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {sanitizeHTML(currentQuestion[`option${option}`])}
                      {isUserAnswer && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: isCorrect ? "green" : "red",
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          {isCorrect ? "(Correct)" : "(Incorrect)"}
                        </span>
                      )}
                      {!isUserAnswer && isCorrect && (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                          }}
                        >
                          (Correct)
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
          </>
        );

      case "textonly":
      default:
        return (
          <>
            <div className="question">
              <span>{sanitizeHTML(currentQuestion.questionTitle)}</span>
            </div>
            <div className="answer">
              {["A", "B", "C", "D", "E"].map((option, index) => {
                const isUserAnswer = userAnswer === option;
                const isCorrect = option === correctAnswer;

                return (
                  <span key={index} className="answersText">
                    <Form.Check
                      type="radio"
                      name="answer"
                      className="custom-radio"
                      checked={isUserAnswer}
                      readOnly
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {sanitizeHTML(currentQuestion[`option${option}`])}
                    {isUserAnswer && (
                      <span
                        style={{
                          marginLeft: "10px",
                          color: isCorrect ? "green" : "red",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {isCorrect ? "(Correct)" : "(Incorrect)"}
                      </span>
                    )}
                    {!isUserAnswer && isCorrect && (
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "green",
                        }}
                      >
                        (Correct)
                      </span>
                    )}
                  </span>
                );
              })}
            </div>
          </>
        );
    }
  };

  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <NavName />
          <Container>
            <div className="QuestionType1Content">
              {loading ? (
                <div>Loading questions...</div>
              ) : (
                <>
                  <div className="noOfQuestion_AP">
                    <span>
                      Question {currentQuestionIndex + 1} of{" "}
                      {fetchedQuestions.length}
                    </span>
                  </div>
                  <div className="progressBar_correctAnswer">
                    <ProgressBar
                      now={progressPercentage}
                      className="custom-progress-bar"
                    />
                  </div>

                  {currentQuestion && (
                    <div className="QuestionPart">{renderQuestionType()}</div>
                  )}

                  <div className="buttonSectionAP">
                    <div className="PreviousBtnAP">
                      <button
                        onClick={handlePrevious}
                        disabled={currentQuestionIndex === 0}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="NextBtnAP">
                      <button onClick={handleNext}>
                        {currentQuestionIndex === fetchedQuestions.length - 1
                          ? "Home"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
