import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import Player from "../assests/images/player.svg";
import GoldMedal from "../assests/images/gold.svg";
import SilverMedal from "../assests/images/silver.svg";
import BrownMedal from "../assests/images/brown.svg";
import Information from "../assests/images/information-circle.svg";
import Ads from "../assests/images/ads.svg";
import Config from "../../config/index";

export default function BottomSection() {
  const [syllabusId, setSyllabusId] = useState("");
  const [mediumId, setMediumId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [banners, setBanners] = useState([]);
  const [topPlayers, setTopPlayers] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    fetchUserDetails();
    fetchTopPlayers();
  }, []);

  useEffect(() => {
    if (syllabusId && mediumId && gradeId) {
      fetchBanners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syllabusId, mediumId, gradeId]);

  useEffect(() => {
    if (banners.length > 1) {
      const bannerInterval = setInterval(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
      }, 10000);
      return () => clearInterval(bannerInterval);
    }
  }, [banners]);

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        throw new Error("User credentials are missing");
      }

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/details/${userId}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const userDetails = await response.json();
      const { grade, syllabus, medium } = userDetails;

      setGradeId(grade._id);
      setSyllabusId(syllabus._id);
      setMediumId(medium._id);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchBanners = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/banner/type/banner?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}&type=advertisment`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const data = await response.json();
      setBanners(data.banners || []);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  const fetchTopPlayers = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/level/top-players`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const playersData = await response.json();
      setTopPlayers(playersData.slice(0, 3));
    } catch (error) {
      console.error("Error fetching top players:", error);
    }
  };

  const currentBannerImage =
    banners.length > 0
      ? `${Config.API_URL}/api/v1/uploads/${banners[currentBannerIndex].image}`
      : Ads;

  return (
    <div>
      <Container>
        <div className="bottomSection">
          <div className="card1_bottom">
            <div className="TopPlayers">
              <span>Top players</span>
            </div>
            <div className="playerSection">
              {topPlayers[0] && (
                <div className="player1">
                  <div className="playerBackGround">
                    <Image src={Player} fluid />
                  </div>
                  <div className="medal">
                    <Image src={GoldMedal} fluid />
                  </div>
                  <div className="PlayerName">
                    <span>{topPlayers[0].name}</span>
                  </div>
                </div>
              )}
              {topPlayers[1] && (
                <div className="player2">
                  <div className="playerBackGround">
                    <Image src={Player} fluid />
                  </div>
                  <div className="medal">
                    <Image src={SilverMedal} fluid />
                  </div>
                  <div className="PlayerName">
                    <span>{topPlayers[1].name}</span>
                  </div>
                </div>
              )}
              {topPlayers[2] && (
                <div className="player3">
                  <div className="playerBackGround">
                    <Image src={Player} fluid />
                  </div>
                  <div className="medal">
                    <Image src={BrownMedal} fluid />
                  </div>
                  <div className="PlayerName">
                    <span>{topPlayers[2].name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card2_bottom">
            <div className="howtoPlay">
              <span>How to Play</span>
            </div>
            <div className="howtoPlay">
              <Image src={Information} alt="logo" />
            </div>
          </div>
          <div className="card3_bottom">
            <div>
              <Image src={currentBannerImage} alt="logo" fluid />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
