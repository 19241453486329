import React, { useState, useEffect } from "react";
import SidebarLayout from "../sidebarMenu/index";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import { Container, Image, Spinner } from "react-bootstrap";
import BottomImg from "../assests/images/bottomLogin.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import PlayerTwo from "../assests/images/player02.svg";
import VS from "../assests/images/vs.svg";
import WelComeSection from "../welcomeSection/index";
import NavName from "../navName/index";
import { useLocation, useNavigate } from "react-router-dom";
import Config from "../../config";
import "./style.css";

export default function OneVsOneStep4() {
  const location = useLocation();
  const {
    selectedSubject,
    selectedPaperType,
    selectedPaperInfo,
    levelNumber,
    playerName,
    gameId,
    subjectId,
    paperTypeId,
    paperInfoId,
  } = location.state || {};
  const [profilePic, setProfilePic] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const userName = localStorage.getItem("UserName");
  const userId = localStorage.getItem("UserID");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    if (userId && token) {
      fetch(`${Config.API_URL}/api/v1/mobile/user/details/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.profilePicture) {
            setProfilePic(
              `${Config.API_URL}/api/v1/uploads/profile-pictures/${data.profilePicture}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile picture:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId, token]);

  const startGame = async () => {
    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/game/start`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId,
            gameId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Game started successfully:", data);
        navigate("/one-vs-one-questions", {
          state: { subjectId, paperTypeId, paperInfoId, levelNumber, gameId , playerName },
        });
      } else {
        console.error("Failed to start the game");
      }
    } catch (error) {
      console.error("Error starting game:", error);
    }
  };
  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            {" "}
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            {" "}
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            {" "}
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <NavName />
          <Container>
            <div className="Step4Container">
              <div className="leftSideCard">
                <div className="topTextSectionStep-4">
                  <div className="readyToGo">
                    <span>Ready to go !</span>
                  </div>
                  <div className="clickStartButtonText">
                    <span>
                      Click start button to start the game with your friend
                    </span>
                  </div>
                  <div className="oneVsOnePlayerNameSection">
                    <div className="playerOne">
                      {loading ? (
                        <Spinner size="large" />
                      ) : (
                        profilePic && (
                          <Image
                            src={profilePic}
                            alt="Profile"
                            roundedCircle
                            className="profileOnevsOne"
                          />
                        )
                      )}
                      <div className="PlayerNameOneVsOne">
                        <span>{userName}</span>
                      </div>
                    </div>
                    <div className="vsImage">
                      <Image src={VS} alt="player" fluid />
                    </div>
                    <div className="playerTwo">
                      <Image src={PlayerTwo} alt="player" fluid />
                      <div className="PlayerNameOneVsOne">
                        <span>{playerName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="OneVsOneStartButton">
                    <button onClick={startGame}>Start</button>
                  </div>
                </div>
              </div>
              <div className="rightSideCard">
                <div className="SelectedSyllabusText">
                  <span>Selected syllabus</span>
                </div>
                <div className="selectedSyllabusForm">
                  <form className="OneVsOneForm">
                    <div className="fieldName">
                      <span>Subject</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select your subject"
                        value={selectedSubject}
                        readOnly
                      />
                    </div>

                    <div className="fieldName">
                      <span>Paper Type</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select your paper type"
                        value={selectedPaperType}
                        readOnly
                      />
                    </div>

                    <div className="fieldName">
                      <span>Paper</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select the paper"
                        value={selectedPaperInfo}
                        readOnly
                      />
                    </div>

                    <div className="fieldName">
                      <span>Level</span>
                    </div>

                    <div className="Input">
                      {" "}
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Select the level"
                        value={levelNumber}
                        readOnly
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
