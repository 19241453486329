import React from "react";
import SidebarLayout from "../sidebarMenu/index";
import { Container, Image } from "react-bootstrap";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import VictoryImg from "../assests/images/victoryOneStar.svg";
import AlarmClock from "../assests/images/alarmClock.svg";
import CorrectAnswer from "../assests/images/correctAnswer.svg";
import WrongAnswer from "../assests/images/wrongAsnwer.svg";
import PlusPoint from "../assests/images/plusPoint.svg";
import WelComeSection from "../welcomeSection/index";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

export default function VictoryOneStar() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    totalTime = 0,
    correctAnswersCount = 0,
    wrongAnswersCount = 0,
    userAnswers,
    questions,
    syllabusId,
    mediumId,
    gradeId,
    subject,
    paperType,
    paperInfo,
    levelNumber,
    time,
    noOfQuestions,
  } = location.state || {};

  const convertSecondsToMinutes = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const formattedTime = convertSecondsToMinutes(totalTime);

  const handleCheckAnswers = () => {
    const from = location.state?.from;

    if (from === "/challange-your-self") {
      navigate("/challange-your-self-answer", {
        state: {
          subject,
          paperType,
          paperInfo,
          noOfQuestions,
          userAnswers,
        },
      });
    } else if (from === "/question-type-1") {
      navigate("/correct-answer", {
        state: {
          userAnswers,
          questions,
          syllabusId,
          mediumId,
          gradeId,
          subject,
          paperType,
          paperInfo,
          levelNumber,
        },
      });
    }else {
      navigate("/home");
    }
  };

  const handleTryAgain = () => {
    const from = location.state?.from;

    if (from === "/question-type-1") {
      navigate("/question-type-1", {
        state: {
          syllabusId,
          mediumId,
          gradeId,
          subject,
          paperType,
          paperInfo,
        },
      });
    } else if (from === "/challange-your-self") {
      navigate("/challange-your-self", {
        state: {
          subject,
          paperType,
          paperInfo,
          time,
          noOfQuestions,
        },
      });
    } else {
      navigate("/home");
    }
  };

  return (
    <SidebarLayout>
      <div className="backgroundTopSectionQT">
        <Container>
          <div className="LeftCloud">
            {" "}
            <Image src={LeftCloud} alt="Cloud" fluid />
          </div>
          <div className="Sun">
            {" "}
            <Image src={Sun} alt="Sun" fluid />
          </div>
          <div className="RightCloud">
            {" "}
            <Image src={RightCloud} alt="Cloud" fluid />
          </div>
        </Container>

        <div className="ContentQT">
          <WelComeSection />
          <Container>
            <div className="victoryFrame">
              <div className="victoryImg">
                <Image src={VictoryImg} alt="victoryImg" fluid />
              </div>
              <div className="alarmClock">
                <Image
                  src={AlarmClock}
                  alt="alarmClock"
                  className="alarmClockImg"
                  fluid
                />
                <div className="spentTime">
                  <span>Spent time</span>
                  <span>{formattedTime}</span>
                </div>
              </div>
              <div className="CorrectAndWrongPoint">
                <div className="correct">
                  <Image src={CorrectAnswer} alt="CorrectAnswer" fluid />
                  <div className="NoOfCorrectAnswer">
                    <span className="NoOfPoint">{correctAnswersCount}</span>
                    <span className="correctText">Correct</span>
                  </div>
                </div>
                <div className="plusPoint">
                  <div className="NoOfPlusPoint">
                    <span> + {correctAnswersCount}</span>
                  </div>
                  <Image
                    src={PlusPoint}
                    alt="PlusPoint"
                    className="PlusPintIcon"
                    fluid
                  />
                </div>
                <div className="wrong">
                  <Image src={WrongAnswer} alt="WrongAnswer" fluid />
                  <div className="NoOfWrongAnswer">
                    <span className="NoOfWrongPoint">{wrongAnswersCount}</span>
                    <span className="wrongText">Wrong</span>
                  </div>
                </div>
              </div>
              <div className="victoryBtnSection">
                <div className="nextBtnVictory">
                  <button onClick={handleTryAgain}>Try Again</button>
                </div>
                <div className="checkAnswerBtn">
                  <button onClick={handleCheckAnswers}>Check Answers</button>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="BottomImgQT">
          <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
          <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
          <Image
            className="BottomImg"
            style={{ display: "inline-block" }}
            src={BottomImg}
            alt="logo"
            fluid
          />
        </div>
      </div>
    </SidebarLayout>
  );
}
