import React from "react";
import { Modal, Spinner, Image } from "react-bootstrap";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";
import Copy from "../assests/images/copy.svg";

export default function TeamVsTeamStep_3({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} className="modalPlay">
      <Modal.Header className="modalHeader" closeButton></Modal.Header>
      <Modal.Body>
        <div className="teamVsModal">
          <Image src={Team} alt="vsLogo" fluid rounded />
          <Image src={VS} alt="vsLogo" fluid rounded />
          <Image src={Team} alt="vsLogo" fluid rounded />
        </div>
        <div className="beReadyText">
          <span>Please wait ..</span>
        </div>
        <div className="gameWillStartText">
          <span>Your friends are connecting..</span>
        </div>
        <div className="spinnerAnimation">
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="pleseWaitText">
          <span>Game ID</span>
          <span style={{ display: "flex" }}>
            421265 &nbsp;&nbsp;
            <Image src={Copy} alt="logo" fluid />
          </span>
        </div>
        <div className="gameIdShowText">
          <span>Share this game id with your friends and play as a team</span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
