import React, { useState, useRef } from "react";
import { Modal, Image, Alert } from "react-bootstrap";
import JoinGame2 from "./TeamVsTeamJoinGame2";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";
import Config from "../../config/index";
import "./style.css";

export default function TeamVsTeamJoinGame1({ show, handleClose }) {
  const [gameId, setGameId] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [showJoinGame2, setShowJoinGame2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [gameData, setGameData] = useState(null);

  const handleInputChange = (e, index) => {
    const newGameId = [...gameId];
    newGameId[index] = e.target.value;
    setGameId(newGameId);

    if (e.target.value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !gameId[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleJoinGame = async () => {
    const completeGameId = gameId.join("");
    const userId = localStorage.getItem("UserID");
    const authToken = localStorage.getItem("authToken");


    console.log("Calling......")

    if (completeGameId.length < 6) {
      setError("Please enter a valid Game ID");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/multi-player-game/join`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            gameId: completeGameId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to join the game");
      }

      const data = await response.json();
      setGameData(data.gameData);

      setSuccess(true);
      handleClose();
      setTimeout(() => {
        setShowJoinGame2(true);
      }, 250);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCreateGame = () => {
    setShowJoinGame2(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modalPlay">
        <Modal.Header className="modalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="teamVsModal">
            <Image src={Team} alt="vsLogo" fluid rounded />
            <Image src={VS} alt="vsLogo" fluid rounded />
            <Image src={Team} alt="vsLogo" fluid rounded />
          </div>

          <div className="enterGameIdText">
            <span>Please Enter team’s Game ID here</span>
          </div>

          <div className="gameIdText">
            <span>Game ID</span>
          </div>
          <div className="game-id-inputs">
            {gameId.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="game-id"
              />
            ))}
          </div>

          {loading && <div className="loading-animation"></div>}

          {error && <Alert variant="danger">{error}</Alert>}
          {success && (
            <Alert variant="success">Successfully joined the game!</Alert>
          )}

          <div className="modalStartBtn">
            <button onClick={handleJoinGame}>Join</button>
          </div>
          <div className="gameIdShowText">
            <span>
              Enter game id which is showing on your friend’s game screen
            </span>
          </div>
        </Modal.Body>
      </Modal>
      <JoinGame2
        show={showJoinGame2}
        handleClose={handleCloseCreateGame}
        gameData={gameData}
      />
    </div>
  );
}
