import React, { useState, useRef, useEffect } from "react";
import Config from "../../config/index";
import {
  Container,
  Image,
  Dropdown,
  DropdownButton,
  InputGroup,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LoginLogo from "../assests/images/loginLogo.svg";
import Sun from "../assests/images/sun.gif";
import LeftCloud from "../assests/images/leftCloud.svg";
import RightCloud from "../assests/images/rightCloud.svg";
import LeftAnimal from "../assests/images/leftAnimalLogin.svg";
import RightAnimal from "../assests/images/RightAnimal.svg";
import GrassLeft from "../assests/images/grassLeft.svg";
import GrassRight from "../assests/images/grassRight.svg";
import BottomImg from "../assests/images/bottomLogin.svg";
import { get } from "country-flag-emoji";

export default function Index() {
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedDialCode, setSelectedDialCode] = useState("Code");
  const [searchQuery, setSearchQuery] = useState("");
  const [phone, setPhone] = useState("");
  const [gameId, setGameId] = useState(["", "", "", "", "", ""]);
  const [otpSent, setOtpSent] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  const [error, setError] = useState("");
  const inputRefs = useRef([]);
  const location = useLocation();
  const navigate = useNavigate();

  const { fullName, email, password, selectedCountry } = location.state;

  useEffect(() => {
    fetch(`${Config.API_URL}/api/v1/country`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
        setFilteredCountries(data);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredCountries(countries);
    } else {
      const filtered = countries.filter(
        (country) =>
          country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          country.dial_code.includes(searchQuery)
      );
      setFilteredCountries(filtered);
    }
  }, [searchQuery, countries]);

  const handleInputChange = (e, index) => {
    const newGameId = [...gameId];
    newGameId[index] = e.target.value;
    setGameId(newGameId);

    if (e.target.value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !gameId[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const sendOtp = () => {
    if (!phone) {
      setError("Please enter a valid phone number.");
      return;
    }

    setError("");

    let sendOtpUrl = "";
    if (phone.startsWith("76")) {
      sendOtpUrl = "https://subapi.examhub.lk/DalogPinRequest";
    } else if (phone.startsWith("71")) {
      sendOtpUrl = "https://subapi.examhub.lk/MobitelPinRequest";
    }

    const payload = {
      subscriberId: `tel:${selectedDialCode.replace("+", "")}${phone}`,
    };

    fetch(sendOtpUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === "S1000") {
          setOtpSent(true);
          setReferenceNo(data.referenceNo);
        } else {
          setError("Failed to send OTP. Please try again.");
        }
      })
      .catch((error) => setError("Error sending OTP. Please try again."));
  };

  const verifyOtp = () => {
    const otp = gameId.join("");
    if (otp.length !== 6) {
      setError("Please enter the 6-digit OTP.");
      return;
    }

    let verifyOtpUrl = "";
    if (phone.startsWith("76")) {
      verifyOtpUrl = "https://subapi.examhub.lk/VerifyDialogPin";
    } else if (phone.startsWith("71")) {
      verifyOtpUrl = "https://subapi.examhub.lk/VerifyMobitelPin";
    }

    const payload = {
      otp: otp,
      referenceNo: referenceNo,
    };

    fetch(verifyOtpUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === "S1000") {
          sendSubscriptionData();
          completeSignUp();
        } else {
          setError("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => setError("Error verifying OTP. Please try again."));
  };

  const completeSignUp = () => {
    const payload = {
      name: fullName,
      email: email,
      phone: `${selectedDialCode}${phone}`,
      country: selectedCountry,
      password: password,
    };

    fetch(`${Config.API_URL}/api/v2/mobile/user/signup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Signup failed. Please try again.");
        }
      })
      .then((data) => {
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("UserName", data.data.name);
        localStorage.setItem("UserID", data.data._id);

        navigate("/home");
      })
      .catch((error) => {
        setError(error.message || "Error completing signup. Please try again.");
      });
  };

  const sendSubscriptionData = () => {
    let subscriptionType = phone.startsWith("76") ? "ideamart" : "mspace";

    const subscriptionPayload = {
      phone: `${selectedDialCode}${phone}`,
      subscriptionId: referenceNo,
      type: subscriptionType,
    };

    fetch(`${Config.API_URL}/api/v1/mobile/subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(subscriptionPayload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Subscription data submission failed.");
        }
      })
      .then((data) => {
        console.log("Subscription data saved successfully:", data);
      })
      .catch((error) => {
        setError(
          error.message || "Error saving subscription data. Please try again."
        );
      });
  };

  return (
    <div className="LoginContainer">
      <Container>
        <div className="LeftCloud">
          <Image src={LeftCloud} alt="Cloud" fluid />
        </div>
        <div className="Sun">
          <Image src={Sun} alt="Sun" fluid />
        </div>
        <div className="RightCloud">
          <Image src={RightCloud} alt="Cloud" fluid />
        </div>

        <center>
          <div className="loginForm">
            <div className="loginLogo">
              <Image src={LoginLogo} alt="loginLogo" fluid />
            </div>
            <div className="Welcometext">
              <span>User Registration</span>
            </div>
            <div className="welcomesubtext">
              <span>Enter your mobile number</span>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}

            <div>
              <div className="InputText">
                <span>Phone Number</span>
              </div>
              <InputGroup className="mb-3">
                <DropdownButton
                  variant="light"
                  title={selectedDialCode}
                  id="input-group-dropdown-1"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <div
                    style={{
                      padding: "10px",
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search by country or dial code"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ marginBottom: "10px" }}
                    />
                    {filteredCountries.length > 0 ? (
                      filteredCountries.map((country) => {
                        const emojiFlag = get(country.code)?.emoji || "🏳️";
                        return (
                          <Dropdown.Item
                            key={country.code}
                            onClick={() =>
                              setSelectedDialCode(country.dial_code)
                            }
                          >
                            <span style={{ marginRight: "10px" }}>
                              {emojiFlag}
                            </span>
                            {country.name} ({country.dial_code})
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item disabled>
                        No matching results
                      </Dropdown.Item>
                    )}
                  </div>
                </DropdownButton>
                <Form.Control
                  aria-label="Text input with dropdown button"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="InputText">
              {!otpSent ? (
                <Button variant="primary" onClick={sendOtp}>
                  Send Code
                </Button>
              ) : (
                <span>OTP Sent!</span>
              )}
            </div>
            <br />
            <br />

            {otpSent && (
              <>
                <div className="verficationText">
                  <span>
                    We have sent the verification code to your mobile number
                  </span>
                </div>

                <div className="game-id-inputs-signup">
                  {gameId.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="game-id-signup"
                    />
                  ))}
                </div>
                <div className="SignInBtn">
                  <button onClick={verifyOtp}>Done</button>
                </div>
              </>
            )}
            <div className="BackBtn">
              <NavLink to="/signup-step-1">
                <button>Back</button>
              </NavLink>
            </div>

            <div className="SignUpText">
              <span className="SignUpText">Already have an account ?</span>
              &nbsp;&nbsp;
              <NavLink to="/">
                <span className="signup">Log In</span>
              </NavLink>
            </div>
          </div>
        </center>
        <div className="LeftAnimal">
          <Image className="LeftAnimalImg" src={LeftAnimal} alt="logo" fluid />
        </div>
      </Container>
      <div className="Bottom">
        <div className="RightAnimal">
          <Image
            className="RightAnimalImg"
            src={RightAnimal}
            alt="logo"
            fluid
          />
        </div>
        <Image className="GrassLeft" src={GrassLeft} alt="logo" fluid />
        <Image className="GrassRight" src={GrassRight} alt="logo" fluid />
        <Image className="BottomImg" src={BottomImg} alt="logo" fluid />
      </div>
    </div>
  );
}
