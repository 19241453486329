import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import ModalIcon from "../assests/images/modalIcon.svg";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";
import TeamVsTeamStep2Create from "./TeamVsTeamModalStep_2";
import TeamVsTeamJoin1 from "./TeamVsTeamJoinGame1";
import "./style.css";

export default function TeamVsTeamModal({ show, handleClose }) {
  const [showTeamVsTeamStep2Create, setShowTeamVsTeamStep2Create] =
    useState(false);
  const [showTeamVsTeamJoin1, setShowTeamVsTeamJoin1] = useState(false);

  const handleOpenCreateGame = () => {
    handleClose();
    setTimeout(() => {
      setShowTeamVsTeamStep2Create(true);
    }, 250);
  };

  const handleJoinGame = () => {
    handleClose();
    setTimeout(() => {
      setShowTeamVsTeamJoin1(true);
    }, 250);
  };

  const handleCloseCreateGame = () => {
    setShowTeamVsTeamStep2Create(false);
    setShowTeamVsTeamJoin1(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modalPlay">
        <Modal.Header className="modalHeader" closeButton></Modal.Header>
        <Modal.Body>
          <div className="ModelIcon">
            <img src={ModalIcon} alt="icon" />
          </div>
          <div className="oneVsCreateNewText">
            <span>Create new team or Join another team</span>
          </div>
          <div className="teamVsModal">
            <Image src={Team} alt="vsLogo" fluid rounded />
            <Image src={VS} alt="vsLogo" fluid rounded />
            <Image src={Team} alt="vsLogo" fluid rounded />
          </div>
          <div className="oneVsButtonSection">
            <div className="createGameBtn">
              <button onClick={handleOpenCreateGame}>Create Game</button>
            </div>

            <div className="joinGameBtn">
              <button onClick={handleJoinGame}>Join Game</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <TeamVsTeamStep2Create
        show={showTeamVsTeamStep2Create}
        handleClose={handleCloseCreateGame}
      />
      <TeamVsTeamJoin1
        show={showTeamVsTeamJoin1}
        handleClose={handleCloseCreateGame}
      />
    </div>
  );
}
