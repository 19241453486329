import React, { useState } from "react";
import { Container, Image, Modal, Form, Spinner } from "react-bootstrap";
import "./style.css";
import VS from "../assests/images/vs.svg";
import Team from "../assests/images/team.svg";
import Challenge from "../assests/images/challengeYour.svg";
import ModalIcon from "../assests/images/modalIcon.svg";
import BtnAnimal from "../assests/images/btnAnimal.svg";
import OneVsOneModal from "../modal/OneVsOneModal";
import TeamVsTeamModal from "../modal/TeamVsTeamModal";
import { NavLink, useNavigate } from "react-router-dom";
import Config from "../../config";

export default function Index() {
  const [subjects, setSubjects] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperInfo, setPaperInfo] = useState([]);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingPaperTypes, setLoadingPaperTypes] = useState(false);
  const [loadingPaperInfo, setLoadingPaperInfo] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedPaperType, setSelectedPaperType] = useState("");
  const [selectedQuestionCount, setSelectedQuestionCount] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedPaperInfo, setSelectedPaperInfo] = useState("");
  const [syllabusId, setSyllabusId] = useState("");
  const [mediumId, setMediumId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const navigate = useNavigate();

  const [showPlayModal, setShowPlayModal] = useState(false);
  const [showFriendsModal, setShowFriendsModal] = useState(false);
  const [showChallengeModal, setShowChallengeModal] = useState(false);
  const [showOneVsOneModal, setShowOneVsOneModal] = useState(false);
  const [showTeamVsTeamModal, setShowTeamVsTeamModal] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  const handlePlayModal = () => {
    setShowPlayModal(true);
    fetchUserDetails();
    fetchPaperTypes();
  };
  const handleFriendsModal = () => setShowFriendsModal(true);
  const handleChallengeModal = () => {
    setShowChallengeModal(true);
    fetchUserDetails();
    fetchPaperTypes();
  };

  const handleOneVsOneModal = () => {
    setShowFriendsModal(false);
    setShowOneVsOneModal(true);
  };

  const handleTeamVsTeamModal = () => {
    setShowFriendsModal(false);
    //setShowTeamVsTeamModal(true);
    setShowComingSoon(true);
  };

  const handleClose = () => {
    setShowPlayModal(false);
    setShowFriendsModal(false);
    setShowChallengeModal(false);
    setShowOneVsOneModal(false);
    setShowTeamVsTeamModal(false);
    setSelectedSubject("");
    setSelectedPaperType("");
    setSelectedPaperInfo("");
    setSelectedQuestionCount("");
    setSelectedTime("");
    setShowComingSoon(false);
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem("UserID");
      const authToken = localStorage.getItem("authToken");

      if (!userId || !authToken) {
        throw new Error("User credentials are missing");
      }

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const userDetails = await response.json();

      const { grade, syllabus, medium } = userDetails;
      const { _id: fetchedGradeId } = grade;
      const { _id: fetchedSyllabusId } = syllabus;
      const { _id: fetchedMediumId } = medium;

      setGradeId(fetchedGradeId);
      setSyllabusId(fetchedSyllabusId);
      setMediumId(fetchedMediumId);

      await fetchSubjects(fetchedSyllabusId, fetchedMediumId, fetchedGradeId);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchSubjects = async (syllabusId, mediumId, gradeId) => {
    setLoadingSubjects(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/subject/by-syllabus-medium-grade?syllabusId=${syllabusId}&mediumId=${mediumId}&gradeId=${gradeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setSubjects(result.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoadingSubjects(false);
    }
  };

  const fetchPaperTypes = async (selectedSubjectId) => {
    setLoadingPaperTypes(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperType/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubjectId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperTypes(result.paperTypes || []);
    } catch (error) {
      console.error("Error fetching paper types:", error);
    } finally {
      setLoadingPaperTypes(false);
    }
  };

  const fetchPaperInfo = async (selectedPaperTypeId) => {
    setLoadingPaperInfo(true);
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(
        `${Config.API_URL}/api/v1/mobile/paperInfo/byFilter?syllabusId=${syllabusId}&gradeId=${gradeId}&subjectId=${selectedSubject}&paperTypeId=${selectedPaperTypeId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const result = await response.json();
      setPaperInfo(result.paperInfos || []);
    } catch (error) {
      console.error("Error fetching paper info:", error);
    } finally {
      setLoadingPaperInfo(false);
    }
  };

  const handleSubjectChange = async (e) => {
    const selectedSubjectId = e.target.value;
    setSelectedSubject(selectedSubjectId);

    if (selectedSubjectId && syllabusId && gradeId) {
      await fetchPaperTypes(selectedSubjectId);
    }
  };

  const handlePaperTypeChange = async (e) => {
    const selectedPaperTypeId = e.target.value;
    setSelectedPaperType(selectedPaperTypeId);

    if (selectedPaperTypeId && syllabusId && gradeId && selectedSubject) {
      await fetchPaperInfo(selectedPaperTypeId);
    }
  };

  const handleStartClick = () => {
    if (selectedSubject && selectedPaperType && selectedPaperInfo) {
      navigate("/challange-your-self", {
        state: {
          subject: selectedSubject,
          paperType: selectedPaperType,
          paperInfo: selectedPaperInfo,
          noOfQuestions: selectedQuestionCount,
          time: selectedTime,
          syllabusId: syllabusId,
          mediumId: mediumId,
          gradeId: gradeId,
        },
      });
    } else {
      alert("Please select all fields before proceeding!");
    }
  };

  const handleLevelStartClick = () => {
    if (selectedSubject && selectedPaperType && selectedPaperInfo) {
      navigate("/game-map", {
        state: {
          subject: selectedSubject,
          paperType: selectedPaperType,
          paperInfo: selectedPaperInfo,
          syllabusId: syllabusId,
          mediumId: mediumId,
          gradeId: gradeId,
        },
      });
    } else {
      alert("Please select all fields before proceeding!");
    }
  };

  return (
    <div>
      <Container style={{ marginBottom: "50px" }}>
        <div className="OtherOptions">
          <span>Play section</span>
        </div>

        <div className="cardSection">
          <div className="card1">
            <div className="playwithText">
              <span>Let’s Play</span>
            </div>
            <div className="cardPane">
              <div className="leftPane">
                <span>Hey! Play different quiz on your favorite subject</span>
              </div>
              <div className="rightPane"></div>
            </div>
            <div className="playButtonSection">
              <div className="btnAnimal">
                <img src={BtnAnimal} alt="animal" />
              </div>
              <div className="InviteBtnCard1">
                <button onClick={handlePlayModal}>Play Now</button>
              </div>
            </div>
          </div>

          <Modal
            className="modalPlay"
            show={showPlayModal}
            onHide={handleClose}
          >
            <Modal.Header className="modalHeader" closeButton></Modal.Header>
            <Modal.Body>
              <div className="ModelIcon">
                <img src={ModalIcon} alt="icon" />
              </div>
              <div className="oneMoreText">
                <span>One More Step to go !</span>
              </div>
              <div className="pleseSelectText">
                <span>Please select to start the game.</span>
              </div>

              <form>
                <div>
                  <div className="InputText-PlayModal">
                    <span>Subject</span>
                  </div>
                  {loadingSubjects ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your subject"
                      value={selectedSubject}
                      onChange={handleSubjectChange}
                    >
                      <option>Select your syllabus</option>
                      {subjects.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>

                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Paper Type</span>
                  </div>
                  {loadingPaperTypes ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your paperType"
                      value={selectedPaperType}
                      onChange={handlePaperTypeChange}
                    >
                      <option>Select your paperType</option>
                      {paperTypes.map((paperType) => (
                        <option key={paperType._id} value={paperType._id}>
                          {paperType.paperTypeName}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Paper Info</span>
                  </div>
                  {loadingPaperInfo ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your paper info"
                      value={selectedPaperInfo}
                      onChange={(e) => setSelectedPaperInfo(e.target.value)}
                    >
                      <option>Select your paper info</option>
                      {paperInfo.map((info) => (
                        <option key={info._id} value={info._id}>
                          {info.paperInfo}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>
              </form>

              <div className="modalStartBtn">
                <button type="button" onClick={handleLevelStartClick}>
                  Start
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <div className="card2">
            <div className="playwithText">
              <span>Play with Friends</span>
            </div>
            <div className="cardPane">
              <div className="leftPane">
                <span>Invite your friends and play with them</span>
              </div>
              <div className="rightPane">
                <div>
                  <Image
                    className="team"
                    src={Team}
                    alt="vsLogo"
                    fluid
                    rounded
                  />
                  <Image className="vs" src={VS} alt="vsLogo" fluid rounded />
                  <Image
                    className="team"
                    src={Team}
                    alt="vsLogo"
                    fluid
                    rounded
                  />
                </div>
                <div>
                  <span>1</span>
                  <img className="vs" src={VS} alt="vsLogo" />
                  <span>1</span>
                </div>
              </div>
            </div>
            <div className="btnAnimal">
              <img src={BtnAnimal} alt="animal" />
            </div>
            <div className="InviteBtnCard2">
              <button onClick={handleFriendsModal}>Play with Friends</button>
            </div>
          </div>

          <Modal
            show={showFriendsModal}
            onHide={handleClose}
            className="modalPlay"
          >
            <Modal.Header className="modalHeader" closeButton></Modal.Header>
            <Modal.Body>
              <div className="ModelIcon">
                <img src={ModalIcon} alt="icon" />
              </div>
              <div className="selectGameModeText">
                <span>Please Select the Game mode</span>
              </div>
              <div>
                <NavLink style={{ textDecoration: "none" }} to="#one">
                  <div className="oneVsOneText" onClick={handleOneVsOneModal}>
                    <span>1</span>
                    <img className="vs" src={VS} alt="vsLogo" />
                    <span>1</span>
                  </div>
                  <div className="buttonText">
                    {" "}
                    <span>One vs One</span>
                  </div>
                </NavLink>

                <NavLink to="#team" style={{ textDecoration: "none" }}>
                  <div className="teamVSteam" onClick={handleTeamVsTeamModal}>
                    <div>
                      <Image
                        className="team"
                        src={Team}
                        alt="vsLogo"
                        fluid
                        rounded
                      />
                      <Image
                        className="vs"
                        src={VS}
                        alt="vsLogo"
                        fluid
                        rounded
                      />
                      <Image
                        className="team"
                        src={Team}
                        alt="vsLogo"
                        fluid
                        rounded
                      />
                    </div>
                  </div>
                  <div className="buttonText">
                    <span>Team vs Team</span>
                  </div>
                </NavLink>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showComingSoon}
            onHide={handleClose}
            className="modalPlay"
          >
            <Modal.Header className="modalHeader" closeButton></Modal.Header>
            <Modal.Body>
              <div className="ModelIcon">
                <img src={ModalIcon} alt="icon" />
              </div>
              <div className="selectGameModeText">
                {" "}
                <span>Coming soon! Stay tuned.</span>
                <div style={{ marginTop: "30px" }}>
                  <Image
                    className="team"
                    src={Team}
                    alt="vsLogo"
                    fluid
                    rounded
                  />
                  <Image className="vs" src={VS} alt="vsLogo" fluid rounded />
                  <Image
                    className="team"
                    src={Team}
                    alt="vsLogo"
                    fluid
                    rounded
                  />
                </div>
              </div>
              <div className="spinnerAnimation">
                <Spinner animation="border" role="status" className="spinner">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Modal.Body>
          </Modal>

          <div className="card3">
            <div className="playwithText">
              <span>Challenge Yourself</span>
            </div>
            <div className="cardPane">
              <div className="leftPane">
                <span>Do you like to set a goal and challenge yourself?</span>
              </div>
              <div className="rightPane">
                <img
                  src={Challenge}
                  alt="vsLogo"
                  style={{ marginTop: "-50px" }}
                />
              </div>
            </div>
            <div className="InviteBtnCard3">
              <button onClick={handleChallengeModal}>Start Challenging</button>
            </div>
          </div>

          <Modal
            show={showChallengeModal}
            onHide={handleClose}
            className="modalPlay"
          >
            <Modal.Header className="modalHeader" closeButton></Modal.Header>
            <Modal.Body>
              <div className="ModelIcon">
                <img src={ModalIcon} alt="icon" />
              </div>
              <div className="oneMoreText">
                <span>Challenge to Yourself !</span>
              </div>
              <div className="pleseSelectText">
                <span>Please select the Subject and Paper or Lesson</span>
              </div>
              <form>
                <div>
                  <div className="InputText-PlayModal">
                    <span>Subject</span>
                  </div>
                  {loadingSubjects ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your subject"
                      value={selectedSubject}
                      onChange={handleSubjectChange}
                    >
                      <option>Select your syllabus</option>
                      {subjects.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>

                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Paper Type</span>
                  </div>
                  {loadingPaperTypes ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your paperType"
                      value={selectedPaperType}
                      onChange={handlePaperTypeChange}
                    >
                      <option>Select your paper Type</option>
                      {paperTypes.map((paperType) => (
                        <option key={paperType._id} value={paperType._id}>
                          {paperType.paperTypeName}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>

                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Paper Info</span>
                  </div>
                  {loadingPaperInfo ? (
                    <Spinner animation="border" />
                  ) : (
                    <Form.Select
                      aria-label="Select your paper Info"
                      value={selectedPaperInfo}
                      onChange={(e) => setSelectedPaperInfo(e.target.value)}
                    >
                      <option>Select your paper Info</option>
                      {paperInfo.map((info) => (
                        <option key={info._id} value={info._id}>
                          {info.paperInfo}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Question Count</span>
                  </div>
                  <Form.Select
                    aria-label="Default select example"
                    value={selectedQuestionCount}
                    onChange={(e) => setSelectedQuestionCount(e.target.value)}
                  >
                    <option>Select your question count</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Form.Select>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <div className="InputText-PlayModal">
                    <span>Time (Minutes)</span>
                  </div>
                  <Form.Select
                    aria-label="Default select example"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  >
                    <option>Select required time</option>
                    <option value="1">1</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </Form.Select>
                </div>
              </form>
              <div className="modalStartBtn">
                <button onClick={handleStartClick}>Start</button>
              </div>
            </Modal.Body>
          </Modal>

          <OneVsOneModal show={showOneVsOneModal} handleClose={handleClose} />
          <TeamVsTeamModal
            show={showTeamVsTeamModal}
            handleClose={handleClose}
          />
        </div>
      </Container>
    </div>
  );
}
